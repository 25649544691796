import {
    home,
    feed,
    jobs,
    events,
    footer,
    profile,
    sign_up,
    onboard,
    cal_card,
    apprentice,
    learn_more,
    unsubscribe,
    license_page,
    confirm_email,
    absorb_courses,
    send_ceu_updates,
} from './translations/'

export const en = {
    "lang": "en",
    "absorb_courses": {
        "language_instructions": "We offer courses in English and Spanish. If you'd like to view and enroll in our Spanish courses, just click the 'Ver en español' button in the upper right corner.",
        "disclaimer": "All of our courses are available in both English and Spanish, if you complete a course in one language completing it in the second language will not count towards your Cal Fire Fitter Certification CEU requirements.  Please note we can not issue token refunds for taking the same course in two different languages."
    },
    absorb_courses: absorb_courses.en,
    "add_cert": {
        "enter_cert_header": "Enter New Certificate",
        "select_cert": "Select Certification",
        "cert_not_listed": "If you are submitting a certificate that is not in our current list please send a copy of the certificate to Info@acteducate.com and we will add the certificate to our list",
        "name": "Name",
        "ref_num": "Reference #:",
        "classification": "Classification:",
        "rating": "Rating:",
        "issued_date": "Issued Date:",
        "state": "State:",
        "region": "Region:",
        "notes": "Notes:",
        "trainer_name": "Trainer Name:",
        "re_certification_date": "Renewal Date:",
        "employee_id": "Employee Id:",
        "eye_color": "Eye Color:",
        "hair_color": "Hair Color:",
        "weight": "Weight:",
        "height": "Height:",
        "birth_date": "Birth Date:",
        "license_number": "License #:",
        "type": "Type:",
        "expiration_date": "Expiration:",
        "image_header": "Images of Certificate / License",
        "image_front": "Front: ",
        "image_back": "Back: (optional)",
        "submit": "Submit"
    },
    "admin_add": {
        "new_ref": "Add Employer",
        "submit": "Submit"
    },
    "admin_change": {
        "ref_num": "ReferenceId",
        "new_ref_num": "New Employer (CTP)",
        "submit": "Submit",
        "to_individual": "Convert to Individual"
    },
    "admin_controls": {
        "act_header": "Acteducate.com",
        "admin_num": 'Your Business Number is: ',
        "add_emp": "Add Employee",
        "bulk_add": "Bulk Upload Employees",
        "pending_emp": "Pending Employees",
        "employee": "Employee:",
        "employee_email": "Employee Email:",
        "approve": "Approve",
        "delete": "Delete",
        "pending_certs": "Pending Certifications",
        "cert_header": "Certification:",
        "emp_header": "Employee:",
        "other_user_entries": "User Entered Data Fields:",
        "view_images": "View Image(s)",
        "pending_emp_change_header": "Pending Employee Profile Changes",
        "old_profile_header": "Old Profile Info",
        "new_profile_header": "New Profile Info"
    },
    "browse_courses": {
        "course_header": "Courses",
        "pre_req": "Pre-Requisites",
        "enroll": "Enroll",
        "edit": "Edit",
        "delete": "Delete",
        "course_edit": "Course Edits",
        "name": "Name",
        "credits": "Credits",
        "media": "Media-Link",
        "submit_edits": "Submit Edits",
        "create_course": "Create Course",
        "add_pre_req": "Add Pre-Requisite to Selected Courses",
        "select_pre_req": "Select Pre-Requisites",
        "add_course": "Add Course",
        "current_course": "Current Courses",
        "un_enroll": "Un-Enroll"
    },
    "cal_card": cal_card.en,
    "cert_update": {
        "lice_and_cert": "License/Certification Name",
        "renewal_freq": "Renewal Frequency",
        "credits_required": "Credits Required",
        "name": "Name",
        "ref_num": "Reference Number",
        "classification": "Classification",
        "rating": "Rating",
        "date_issued": "Date Issued",
        "state": "State",
        "region": "Region",
        "notes": "Additional Notes",
        "trainer_name": "Trainer Name",
        "re_certification_date": "Re-Certification Date",
        "employee_id": "Employee ID",
        "eye_color": "Eye Color",
        "hair_color": "Hair Color",
        "weight": "Weight",
        "height": "Height",
        "license_number": "License Number",
        "type": "Type",
        "expiration_date": "Expiration Date",
        "choose_fields": "Choose Extra Fields",
        "submit": "Submit"
    },
    "ceu_hours": {
        "external_requested": "External CEUs Requested",
        "ceus_requested": "CEUs Requested:",
        "name": "Name:",
        "email": "Email:",
        "user_info": "User Info",
        "view_request": "View Request"
    },
    "change_super_pass": {
        "change_pass": "Change Password",
        "enter_new_pass": "Enter New Password",
        "submit": "Submit"
    },
    confirm_email: confirm_email.en,
    "course_page": {
        "log_out": "Log Out",
        "act_header": "Acteducate.com"
    },
    "create_cert": {
        "lice_and_cert": "License/Certification",
        "ref_num": "Reference #",
        "submit": "Submit"
    },
    "drag_drop_page": {
        "act_header": "Acteducate.com",
        "back_to_admin": "Back to Admin Controls",
        "download_template": "Download User Entry Template",
        "edit_search": "Edit Search Fields",
        "drag_drop_here": "Drag & Drop Completed User Data Spreadsheet Here, or Click to Browse"
    },
    "edit_jobs": {
        "listing_header": "Work Listing Details",
        "industry": "Industry",
        "title": "Title",
        "description": "Description",
        "edit_listing": "Edit Listing",
        "company_header": "Company Information",
        "address": "Address",
        "email": "Email",
        "phone": "Phone",
        "instructions": "Instructions (optional)",
        "start_date": "Start Date",
        "end_date": "End (optional)",
        "required_certs": "Required Certifications",
        "choose_certs": "Choose Required Certifications"
    },
    "edit_license": {
        "license_name": "License Name: ",
        "ref_num": "Reference #:",
        "submit": "Submit"
    },
    "edit_news": {
        "title": "Title",
        "content": "Content",
        "submit": "Submit"
    },
    "edit_profile": {
        "first_name": "First Name",
        "last_name": "Last Name",
        "phone": "Phone Number",
        "address_1": "Address",
        "address_2": "Apt, Bldg, or Suite",
        "city": "City",
        "state": "State",
        "zip": "Zip",
        "language": "Language",
        "english": "English",
        "spanish": "Spanish",
        "email": "Email",
        "cc": "CC Emails (separate emails by comma)",
        "submit": "Submit"
    },
    "employee_list": {
        "employees": "Employees",
        "sort_by": "Sort By:",
        "active": "Active",
        "classification": "Classification",
        "date_of_hire": "Date of Hire",
        "no_employees": "No Employees to Display",
        "name": "Name",
        "email": "Email",
        "certs": "Certifications",
        "controls": "Controls",
        "info": "Info",
        "no_certs": "No certifications to display"
    },
    "feed": feed.en,
    "field_exports": {
        "name": "Name...",
        "ref_num": "Reference Number...",
        "issued_date": "Issued Date...",
        "rating": "Rating...",
        "state": "State...",
        "region": "Region...",
        "notes": "Additional Notes...",
        "name_on_cert": "Name on Certificate...",
        "trainer_name": "Trainer Name...",
        "re_certification_date": "Re-Certification Date...",
        "employee_id": "Employee Id...",
        "eye_color": "Eye Color...",
        "hair_color": "Hair Color...",
        "weight": "Weight...",
        "height": "Height...",
        "birth_date": "Birth Date...",
        "license_number": "License #...",
        "status": "Status...",
        "credits_required": "Credits Required...",
        "classification": "Classification...",
        "renewal_frequency": "Renewal Frequency...",
        "credits_earned": "Credits Earned...",
        "type": "Type...",
        "expiration_date": "Expiration..."
    },
    "footer": {
        "contact": "Contact",
        "stay_compliant": "Let ACT help you to stay in compliance.",
        "more_info": "For more information, please contact us:",
        "email": "info@acteducate.com",
        "number": "(951) 221-3405",
        "addy": "P.O. Box 6482 Norco, CA 92860"
    },
    footer: footer.en,
    "form_exports": {
        "create_job_header": "Create Job",
        "alt_business_name": "Business Name (optional)",
        "alt_business_name_placeholder": "Defaults to 'Acteducate.com'",
        "job_listing_header": "Job Listing Details",
        "industry": "Industry",
        "title": "Title",
        "description": "Description",
        "company_header": "Company Information",
        "address": "Address",
        "email": "Email",
        "phone": "Phone",
        "instructions": "Instructions (optional)",
        "start_date": "Start Date",
        "end_date": "End (optional)",
        "required_certs": "Required Certifications",
        "choose_certs": "Choose Required Certifications",
        "custom_options": "Custom Options",
        "customize_invite": "Customize Applicants Inviting Instructions",
        "custom_apply_link": "Link to Apply / Interview",
        "applicant_instructions": "Instructions for Applicant",
        "custom_questions": "Custom Questions for Applicants",
        "enter_custom_questions": "Enter Custom Questions Below",
        "submit": "Submit",
        "invite_employee": "Invite Employee to Apply to ",
        "select_employee": "Select Employee to Invite",
        "send_invite": "Send Invite",
        "congratulations": "Congratulations, you've been invited to apply at ",
        "job_title": "Job Title:",
        "job_industry": "Job Industry:",
        "job_desc": "Job Description: ",
        "accept": "Accept",
        "deny": "Deny",
        "enter_industry": "Enter Industry",
        "enter_desc": "Enter Description..."
    },
    "functional_buttons": {
        "log_out": "Log Out",
        "indiv_sign_up": "Individual Sign Up",
        "log_in": "Log In",
        "change_language": "Ver en español"
    },
    "apprentice": apprentice.en,
    "apprentice_requests": {
        "name": "Name:",
        "business_name": "Business Name:",
        "user_info": "User Info",
        "pending_apprentices": "Pending Apprentices",
        "download_form": "Download Form",
        "approve_request": "Approve Request",
        "deny_request": "Deny Request"
    },
    events: events.en,
    "getting_started": {
        "cert_management_header": "Certification Management",
        "cert_management_desc": "Need help tracking and maintaining all your licenses and certifications?  ACT can help you manage them all in one place.  ACT specializes in CA Fire Sprinkler Fitter Certification Management and can help keep you up to date on all the requirements to maintain your license.  What is included in the subscription?\n (The account must be active for no less than 5 months prior to the Cal Fire Renewal period in order to not be charged an additional fee, and the account must be active at the time of the renewal period, any account not meeting these requirements will be charged an additional fee for the Cal Fire Fees.  In the event that any additional fees need to be charged or if there is any change to the fees charged by Cal Fire, ACT will notify all subscribers prior to charging additional fees. )",
        "ca_sprinkler_fitter": "CA Fire Sprinkler Fitter – $45 per month",
        "fitter_management_service": "CalFire Fitter Certification Management Services: ",
        "fitter_desc_0": "We will get you up to date with Cal Fire and help keep it that way.  We include the annual renewal fee in our subscription price, so you don’t have to worry about anything.  A deposit will be charged if you have not already renewed for the initial subscribing year.  After that, just maintain your account with ACT and let us take care of it. ",
        "fitter_desc_1": "Continuing Education Courses: ",
        "fitter_desc_2": "With each monthly payment you will receive 1 token to be applied to any of our continuing education courses.  We are a Cal Fire Approved Provider for continuing education which applies to your CalFire Fitter Certification.  We will keep track of all your CEU hours earned, even those earned elsewhere, and help guide you to complete the necessary requirements to keep your certification up to date.   If you are behind on credits, you will be able to purchase additional tokens to apply to courses to get caught up.",
        "license_and_cert": "License & Certification Records Management:",
        "license_and_cert_desc": "Keep all your licenses and certifications (such as First aid/CPR, Equipment, Etc.) in one place, don’t worry about losing your wallet.  Upload copies of your certifications, licenses etc. to your profile and have access to them anywhere.  We also help you manage those licenses to ensure that you are staying up to date.",
        "news_and_updates": "News & Updates:",
        "news_and_updates_desc": "Exclusive news and updates, know what is going on in the industry that can affect you!",
        "license_and_cert_desc_1": "Keep all your licenses and certifications in one place, don’t worry about losing your wallet.  Upload copies of your certifications to your profile and have access to them anywhere.  We also help you manage those licenses to ensure that you are staying up to date.",
        "job_opportunities": "Job Opportunities",
        "job_opportunities_desc": "Exclusive Job opportunities posted by hiring contractors; search and apply all in one place.",
        "help": "Help when you need it:",
        "help_desc": "Have a question?  Need some guidance?  Contact ACT at info@acteducate.com for assistance.",
        "ca_apprentice": "CA Fire Sprinkler Apprentice – $35 per month",
        "ca_trainee": "CA Fire Sprinkler Trainee – $35 per month",
        "questions": "Have a question?  Need some guidance?  Contact ACT at info@acteducate.com for assistance.",
        "ca_other": "Other – $25 per month",
        "ca_other_desc": "This category is for those working in the Fire Sprinkler industry that need help keeping track of certifications and licenses but don’t have Cal Fire certifications.  This is a great category for Inspectors, Engineers, and others.",
        "approved_provider": "ACT is an Approved Provider of Continuing Education Courses specific to the California Fire Sprinkler Fitter Certification requirements.",
        "course_multi_lingual": "Courses are available in both English and Spanish, online courses allow you to complete your required hours at your convenience.",
        "subscriber_courses": "Subscribers enjoy access to all courses for less than $13 per training hour! ",
        "purchasing_courses": "All courses can be purchased without subscribing at a slightly higher rate than subscribers.",
        "exclusive_news_and_updates": "Get exclusive news and updates applicable to the Fire Sprinkler Industry, be in the know and understand what can be affecting you",
        "contribute_to_news": "Interested in contributing?  Send us an email at info@acteducate.com",
        "manage_employees": "Manage all your employees and their certifications all in one place.  Post jobs to our exclusive job opportunities page and screen applicants.",
        "admin_controls": "Company Administrative Controls",
        "safety_program": "Safety Program",
        "update_safety_program": "Need help updating your safety program or just need a resource to ask questions, let ACT help!",
        "job_opportunity_header": "Exclusive Job Opportunities",
        "interested_in_posting_jobs": "Interested in posting a job opportunity? Contact us at info@acteducate.com"
    },
    "header": {
        "admin_controls": "Admin Controls",
        "apprentice": "Apprentice",
        "change_lang": "Ver en Español",
        "courses": "Courses",
        "home": "Home",
        "jobs": "Jobs",
        "learn_more": "Learn More",
        "license_and_cert": "Fitter Profile",
        "log_in": "Log In",
        "log_out": "Log Out",
        "news": "News",
        "notifications": "Notifications",
        "profile": "My Account",
        "settings": "Settings",
        "sign_up": "Sign Up",
        "super_controls": "Super Controls",
        "rti_tracker": "RTI Tracker",
        "access_denied": "Access Denied: Subscription Required",
        "events": "Events"
    },
    "home": {
        "mission_1": "Associated Compliance & Training (ACT) offers Courses, Certification Management, and Hassle Free Renewals to make Cal Fire Compliance and Certification easy and accessible for you or your business!",
        "mission_2": "Our federally approved Fire Sprinkler Fitter Apprenticeship Program can simplify certifying your English and Spanish. To get your company in compliance, email us at",
        "big_three": "Services Offered",
        "big_three_desc": "Learn more about what ACT offers to help you and your company stay in compliance",
        "cert_management": "Cal Fire Certification Management",
        "cert_management_desc_1": "Keeping your certifications up to date can be frustrating and time-consuming.",
        "cert_management_desc_2": "With ACT, we do the work of keeping your licenses up to date.",
        "cert_management_desc_3": "ACT has the knowledge and experience to help get and keep your team in compliance",
        "cert_management_desc_4": "Let’s work together to make sure your business is able to keep operating without interruption.",
        "edu_courses": "Continuing Education Courses",
        "edu_courses_desc_1": "No more driving, parking, or taking a day off for classes,",
        "edu_courses_desc_2": "Now all your CEU Courses are in one easy to use website.",
        "edu_courses_desc_3": "¡También tenemos clases en español!",
        "edu_courses_desc_4": "We are here to help.",
        "apprentice_training": "Apprenticeship Training",
        "apprentice_training_desc_1": "Are time, cost, and paperwork bogging down your businesses apprentices?",
        "apprentice_training_desc_2": "ACT puts 510 hours of federally approved RTI courses and a team of experts right at your fingertips.",
        "apprentice_training_desc_3": "Our courses are exclusively online and available in both English and Spanish to meet the needs of your diverse team."
    },
    home: home.en,
    "info": {
        "request_quote": "Request Company Quote",
        "mission_statement": "Associated Compliance & Training (ACT) is an organization that was built on the principal of solving problems and helping those working in the Fire Sprinkler Industry to stay in compliance with licensing requirements as well as to provide training through our Apprenticeship program, Continuing Education courses, and Safety.",
        "cert_management": "Certification Management",
        "need_help": "Need help tracking and maintaining all your licenses and certifications?  ACT can help you manage them all in one place.",
        "courses": "Courses",
        "courses_description": "ACT is an Approved Provider of Continuing Education Courses specific to the California Fire Sprinkler Fitter Certification requirements.",
        "news_updates": "News and updates",
        "news_updates_description": "Get exclusive news and updates applicable to the Fire Sprinkler Industry, be in the know and understand what can be affecting you.",
        "admin_controls": "Company Administrative Controls",
        "admin_controls_description": "Manage all your employees and their certifications all in one place.  Post jobs to our exclusive job opportunities page and screen applicants.",
        "saftey_program": "Safety Program",
        "saftey_program_description": "Need help updating your safety program or just need a resource to ask questions, let ACT help!",
        "job_opportunities": "Exclusive Job Opportunities",
        "job_opportunities_description": "Exclusive Fire Sprinkler Industry job opportunities!",
        "job_opportunities_description_2": "Interested in posting a job opportunity?  Contact us at Info@acteducate.com",
        "contact": "Contact",
        "contact_description": "Let ACT help you to stay in compliance. For more information, please contact us at  ",
        "contact_link": "info@acteducate.com"
    },
    "jobs": jobs.en,
    "job_history": {
        "prior_job_header": "Prior Job History",
        "job_title": "Job Title:",
        "job_industry": "Job Industry:",
        "job_desc": "Job Description: ",
        "current_contractors": "Current Contractors:"
    },
    learn_more: learn_more.en,
    "licenses": {
        "lice_and_cert": "License/Certification:",
        "ref_num": "Reference #:",
        "issued_date": "Issued Date:",
        "rating": "Rating:",
        "state": "State:",
        "region": "Region:",
        "notes": "Notes:",
        "trainer_name": "Trainer Name:",
        "re_certification_date": "Renewal Date:",
        "employee_id": "Employee Id:",
        "eye_color": "Eye Color:",
        "hair_color": "Hair Color:",
        "weight": "Weight:",
        "height": "Height:",
        "birth_date": "Birth Date:",
        "license_number": "License #:",
        "status": "Status:",
        "credits_required": "Credits Required:",
        "classification": "Classification:",
        "renewal_frequency": "Renewal Frequency:",
        "credits_earned": "Credits Earned:",
        "type": "Type:",
        "expiration_date": "Expiration:"
    },
    "license_page": license_page.en,
    "log_in": {
        "email": "Email address",
        "password": "Password",
        "submit": "Submit",
        "forgot_pass": "Forgot Password",
        "forgot_back": "Back to Login",
        "reset_email": "Please Enter Your Email",
        "reset_submit": "Reset Password"
    },
    "navbar": {
        "home": "Home",
        "profile": "Profile",
        "courses": "Courses",
        "licenses": "Licenses & Certifications",
        "jobs": "Job Opportunities",
        "feed": "News",
        "settings": "Settings",
        "notifications": "Notifications",
        "contact": "Contact Us",
        "learn_more": "Learn More"
    },
    "notifications": {
        "act_header": "Acteducate.com",
        "notification": "Notifications",
        "invite": "Invites"
    },
    "ojl_hours": {
        "hour_upload_requests": "OJL Hour Upload Requests",
        "name": "Name:",
        "email": "Email:",
        "hours_requested": "Hours Requested:",
        "user_info": "User Info",
        "download_form": "Download Form",
        "approve_request": "Approve Request",
        "deny_request": "Deny Request"
    },
    "onboard": {
        "act_header": "Acteducate.com",
        "please_wait_message": "Please do not refresh the page. If you are not automatically redirected back to the home page in 10 seconds, please click the ACT logo to be redirected to the Home Page."
    },
    onboard: onboard.en,
    "onboard_admin": {
        "business_name": "Business Name",
        "your_email": "Your Email",
        "required": "* Required",
        "first_name": "First Name",
        "last_name": "Last Name",
        "phone": "Phone Number",
        "address_1": "Address",
        "address_2": "Apt, Bldg, or Suite",
        "city": "City",
        "state": "State",
        "zip": "Zip",
        "language": "Language",
        "english": "English",
        "spanish": "Spanish",
        "password": "Password",
        "password_2": "Re-Enter Password",
        "payment_header": "Payment Details",
        "enter_now": "Enter Card Now",
        "enter_later": "Invite Admin to Complete Signup",
        "click_accept": "Click to Accept"
    },
    "pending_quotes": {
        "quotes_pending": "Quotes Pending",
        "business_name": "Business Name:",
        "email": "Email:",
        "questions": "Questions:",
        "send_quote": "Send Quote Price",
        "delete": "Delete Request"
    },
    "placeholders": {
        "search": "Search",
        "enter_title": "Enter Title...",
        "enter_content": "Enter Content",
        "enter_first_name": "Enter First Name",
        "enter_last_name": "Enter Last Name",
        "enter_phone_number": "Enter Phone Number",
        "enter_address": "Enter First Day Address...",
        "enter_address_2": "Enter Apt or Suite",
        "enter_city": "Enter City",
        "enter_state": "Enter State",
        "enter_zip": "Enter Zip",
        "password": "Enter password",
        "password_2": "Enter password again",
        "enter_email": "Enter email",
        "enter_company_name": "Enter Company Name",
        "enter_industry": "Enter Industry...",
        "enter_desc": "Enter Description...",
        "enter_instructions_for_new_hire": "Enter instructions for new hire; expected uniform, what to bring, etc...",
        "enter_hire_link": "(Optional) Enter Link to Apply / Schedule Interview",
        "enter_hire_instructions": "Enter instructions on how the invitee can reach you and / or schedule an interview",
        "enter_new_password": "Enter New Password",
        "enter_license_or_cert": "Enter License/Certification",
        "enter_ref_num": "Enter Reference #",
        "enter_renewal_freq": "Enter Renewal Frequency",
        "enter_required_credits": "Enter # of Credits Required",
        "have_other_questions": "Do you have any other questions?",
        "reason_for_deny": "Reason for denial"
    },
    "popup_exports": {
        "confirm_delete": "Confirm Delete",
        "confirm": "Confirm",
        "confirm_denial": "Confirm Denial",
        "apply": "Apply",
        "proceed_to_payment": "Proceed to Payment Page",
        "quote_price": "Quote Price:",
        "billed_monthly": " (Billed Monthly)",
        "subscription_cost": "Subscription Cost (Annually)",
        "down_cost": "Down Payment Cost (One Time)",
        "fitter_cost": "Cost Per Fitter (Monthly)",
        "apprentice_cost": "Cost Per Non-ACT Apprentice (Monthly)",
        "act_apprentice_cost": "Cost Per ACT Apprentice (Monthly)",
        "trainee_cost": "Cost Per Trainee (Monthly)",
        "other_cost": "Cost Per Other / Non-Fitter (Monthly)",
        "email_quote": "Email Quote",
        "set_price": "Set Price",
        "id": "ID:",
        "name": "Name:",
        "address": "Address:",
        "classification": "Classification:",
        "phone": "Phone:",
        "language": "Language:",
        "data_applied": "Date Applied:",
        "active": "Active",
        "inactive": "Inactive",
        "certifications": "Certifications:",
        "no_certifications": "No Certifications",
        "lice_and_cert": "License/Certification:",
        "ref_num": "Reference #:",
        "issued_date": "Issued Date:",
        "rating": "Rating:",
        "state": "State:",
        "region": "Region:",
        "notes": "Notes:",
        "trainer_name": "Trainer Name:",
        "renewal_date": "Renewal Date:",
        "employee_id": "Employee Id:",
        "eye_color": "Eye Color:",
        "hair_color": "Hair Color:",
        "weight": "Weight:",
        "height": "Height:",
        "birth_date": "Birth Date:",
        "license_number": "License #:",
        "status": "Status:",
        "credits_required": "Credits Required:",
        "renewal_frequency": "Renewal Frequency:",
        "credits_earned": "Credits Earned:",
        "type": "Type:",
        "expiration": "Expiration:",
        "reason_denied": "Reason Denied",
        "required_questions": "Applicant Questions"
    },
    "post_news": {
        "required": "* Required",
        "submit": "Submit",
        "title": "Title",
        "image": "Image",
        "content": "Content"
    },
    "profile": profile.en,
    "request_quote": {
        "request_header": "Request A Business Quote",
        "business_name": "Business Name",
        "required": "* Required",
        "email": "Email",
        "optional": "(optional)",
        "phone": "Phone",
        "questions": "Questions",
        "submit_quote": "Submit Quote Request"
    },
    "reset_password": {
        "change_pass_header": "Change Employees Password",
        "new_pass_header": "Enter New Password",
        "new_pass_placeholder": "Enter Password",
        "submit": "Submit"
    },
    send_ceu_updates: send_ceu_updates.en,
    "settings": {
        "act_header": "Acteducate.com",
        "reset_emp_pass": "Reset Employee Password",
        "add_ID": "Add Employer",
        "change_id": "Change Business ID",
        "remove_id": "Remove Business ID",
        "change_pass": "Change Password",
        "payment_info_header": "Payment Information",
        "plan": "Plan: ",
        "amount": "Amount: ",
        "next_bill_date": "Next Billing Date: ",
        "cancel_plan": "Cancel Plan",
        "change_plan": "Change Plan",
        "payment_settings_header": "Payment Settings",
        "payment_method": "Payment Method: ",
        "update_payment_download_invoice": "Update Payment Settings & Download Invoices",
        "delete_account": "Delete Account"
    },
    "sign_up": {
        "required": "* Required",
        "your_email": "Your Email",
        "first_name": "First Name",
        "last_name": "Last Name",
        "phone": "Phone Number",
        "address_1": "Address",
        "address_2": "Apt, Bldg, or Suite",
        "city": "City",
        "state": "State",
        "zip": "Zip",
        "language": "Language",
        "english": "English",
        "spanish": "Spanish",
        "password": "Password",
        "password_2": "Re-Enter Password",
        "classification": "Classification",
        "select_account_type": "Select Account Type",
        "billing_frequency": "Billing Frequency",
        "billing_frequency_select": "Select Billing Frequency",
        "monthly": "Monthly",
        "quarterly": "Quarterly",
        "bi_annually": "Bi-Annually",
        "annually": "Annually",
        "have_you_renewed": "Have you renewed your Cal Fire License this year?",
        "yes": "Yes",
        "no": "No",
        "selected_subs": "Selected Subscription",
        "additional_deposits_required": "* Additional Deposit may be required",
        "what_to_expect": "What to Expect",
        "excited": "We're excited for you to sign up as a subscriber with ACT. You'll be emailed an CA Fire authorization form, and our team will be contacting you via email within 48 hours to get some more information to complete the setup of your account and to make sure that you are getting the assistance that you need. ",
        "excited_no_sub": "We're excited for you to sign up and start taking your Cal Fire approved CEU classes with ACT. After clicking submit, you'll be redirected to our secure payment portal where your card will be saved on file.  ",
        "no_charge_till_purchase": "Your card will not be charged any sign up or subscription fee. It will be saved with Stripe, our secure payment provider so you don't have to enter your card every time you'd like to purchase tokens.",
        "no_charge_till_approved": "Your card will not be charged until your account is approved by our team.",
        "click_accept": "Click to Accept",
        "submit": "Submit",
        "hide_policy": "Hide Privacy Policy",
        "privacy_policy": "Privacy Policy",
        "hide_terms": "Hide Terms and Conditions",
        "terms_and_conditions": "Terms and Conditions",
        "no_changes_selected": "No Billing Changes Selected",
        "deactivate": "Deactivate",
        "update_card": "Update Card",
        "amount_of": "Amount of",
        "hours_whole_number": "Hours (Whole Numbers)",
        "credits_decimals": "Credits (Decimals)",
        "edit_hours": "Edit Hours:",
        "save": "Save",
        "delete": "Delete",
        "view_pdf": "View PDF",
        "view_image": "View Image"
    },
    sign_up: sign_up.en,
    "sign_up_admin": {
        "company_name": "Company Name",
        "your_email": "Your Email",
        "first_name": "First Name",
        "last_name": "Last Name",
        "phone": "Phone Number",
        "address_1": "Address",
        "address_2": "Apt, Bldg, or Suite",
        "city": "City",
        "state": "State",
        "zip": "Zip",
        "language": "Language",
        "english": "English",
        "spanish": "Spanish",
        "password": "Password",
        "password_2": "Re-Enter Password",
        "expectations": "What to Expect",
        "we_are_excited": "We're excited for you to sign up as a subscriber with ACT. You'll be emailed an CA Fire authorization form, and our team will be contacting you via email within 48 hours to get some more information to complete the setup of your account and to make sure that you are getting the assistance that you need. Your card will not be charged until your account is approved by our team.",
        "click_accept": "Click to Accept",
        "submit": "Submit"
    },
    "sign_up_employee": {
        "ref_num": "Reference Number",
        "required": "* Required",
        "email": "Your Email",
        "account_type": "Account Type",
        "select_account_type": "Select Account Type",
        "first_name": "First Name",
        "last_name": "Last Name",
        "phone": "Phone Number",
        "address_1": "Address",
        "address_2": "Apt, Bldg, or Suite",
        "city": "City",
        "state": "State",
        "zip": "Zip",
        "language": "Language",
        "english": "English",
        "spanish": "Spanish",
        "password": "Password",
        "password_2": "Re-Enter Password",
        "click_accept": "Click to Accept",
        "submit": "Submit"
    },
    "sign_up_admin_employee": {
        "business_id": "Business ID",
        "emp_email": "Employee Email",
        "first_name": "First Name",
        "last_name": "Last Name",
        "phone": "Phone Number",
        "address_1": "Address",
        "address_2": "Apt, Bldg, or Suite",
        "city": "City",
        "state": "State",
        "zip": "Zip",
        "language": "Language",
        "english": "English",
        "spanish": "Spanish",
        "password": "Password",
        "password_2": "Re-Enter Password",
        "click_accept": "Click to Accept",
        "submit": "Submit"
    },
    "super_certs": {
        "submit_new_cert": "Submit New Certification",
        "lice_and_cert": "License/Certification:",
        "cal_fire_reg_card": "Cal Fire Fitter Registration Card",
        "issued_date": "Issued Date:",
        "ceu_credit_period": "CEU Credit Period",
        "status": "Status"
    },
    "super_cert_upload": {
        "title": "Title",
        "required": "* Required",
        "not_required": "Not Required",
        "renewal_frequency": "Renewal Frequency",
        "credits_required": "Credits Required",
        "required_details": "Required Details",
        "name": "Name",
        "ref_num": "Reference Number",
        "classification": "Classification",
        "rating": "Rating",
        "date_issued": "Date Issued",
        "state": "State",
        "region": "Region",
        "notes": "Additional Notes",
        "trainer_name": "Trainer Name",
        "re_certification_date": "Re-Certification Date",
        "employee_id": "Employee ID",
        "eye_color": "Eye Color",
        "hair_color": "Hair Color:",
        "weight": "Weight:",
        "height": "Height:",
        "birth_date": "Birth Date:",
        "license_number": "License #:",
        "type": "Type:",
        "expiration_date": "Expiration:",
        "choose_fields": "Choose Extra Fields",
        "submit": "Submit"
    },
    "super_functionality": {
        "hide_ceu_hour_submittal": "Hide CEU Hours Submittal",
        "cal_card_required": "(Cal Card Required)",
        "payment_failed": "Payment Failed",
        "add_emp_to_admin": "Add Employee Fitter",
        "add_ceu_hours": "Add CEU Hours",
        "add_act_app": "Add ACT Apprentice",
        "send_ceu_update_emails": "Send CEU Updates",
        "download_stripe_audit": "Audit Stripe",
        "view_calendly": "View Apprentice Open Hours",
        "act_header": "Acteducate.com",
        "active_subs": "Active Subscriptions",
        "hide_edit": "Hide Edit",
        "name": "Name:",
        "email": "Email:",
        "info": "Info",
        "certs": "Certifications",
        "admins": "Compliance Training Partners",
        "tokens": "Tokens:",
        "upload_cert": "Upload Cert",
        "cert_name": "Cert Name:",
        "edit": "Edit",
        "delete": "Delete",
        "no_credit": "No Credit",
        "pending_indv": "Pending Individuals",
        "selected_classification": "Selected Classification:",
        "price_and_freq": "Price & Frequency:",
        "has_renewed": "Has Renewed:",
        "no_card_saved": "NO CARD ON FILE",
        "no_card_instructions": "Please reach out to this user and instruct them to log in. They will be redirected to stripe, and after they put in a valid card, this message will dissapear, and the account will be ready for approval",
        "approve": "Approve",
        "approve_header": "Approve Fitter",
        "approving": "Approving: ",
        "set_down_price": "Down Payment",
        "approve_no_down": "Approve Without Down Payment",
        "pending_admin": "Pending Compliance Training Partners",
        "sign_up_admin": "Add New Compliance Training Partner",
        "admin": "Admin:",
        "admin_email": "Admin Email:",
        "pending_admin_changes": "Pending Compliance Training Partner Changes",
        "old_profile_header": "Old Profile Info",
        "new_profile_header": "New Profile Info",
        "deny": "Deny",
        "pending_certs": "Certifications Pending",
        "cert": "Certification:",
        "user": "User:",
        "user_info": "User Info",
        "cert_info": "Cert Info",
        "view_image": "View Image",
        "jobs_pending": "Jobs Pending",
        "reason_for_change": "Reason for Change:",
        "job": "Job:",
        "industry": "Industry:",
        "edit_admin": "Edit Admin",
        "admin_options": "Apprentice Partnership",
        "custom_priced_tokens": "Custom Priced Tokens",
        "edit_pricing_structure": "Edit Pricing Structure",
        "move_all_history": "Move All To History",
        "compliance_training_partner": "Compliance Training Partner:",
        "description": "Description:",
        "company": "Company:",
        "author": "Author",
        "make_corrections": "Make Corrections",
        "denial_reasons": "Denial Reasons",
        "reason": "Reason:",
        "category": "Category",
        "external_ceu_hours": "External CEU Hours",
        "hours_whole_numbers": "Hours (Whole Numbers)",
        "credits_decimals": "Credits (Decimals)",
        "deactivate": "Deactivate",
        "update_card": "Update Card",
        "billing_frequency": "Billing Frequency:",
        "company": "Company:",
        "has_apprentice": "Apprentice Partner:",
        "fitter_count": "Fitters:",
        "apprentice_count": "Apprentices:",
        "business_number": "Business Number:"
    },
    "super_navbar": {
        "home": "Home",
        "learn_more": "Learn More",
        "available_courses": "Available Courses",
        "job_opportunities": "Job Opportunities",
        "profile": "Profile",
        "add_cert": "Add Certifications"
    },
    "table_display": {
        "review": "Review",
        "finalize": "Review and Finalize",
        "delete": "Delete Selected",
        "create": "Create Selected"
    },
    "toast": {
        "user_approved": "User Approved!",
        "cert_approved": "Certification Approved!",
        "changes_approved": "User Changes Approved!",
        "news_edited_success": "News Post Edited Successfully",
        "news_posted": "News Story Posted",
        "account_pending": "Account Pending!",
        "please_enter_all_required": "Please enter all required * fields",
        "account_created": "Account Created!",
        "cert_request_sent": "Certification Request Sent!",
        "double_check_inputs": "Something went wrong, please double check all inputs and try again",
        "employer_added": "Employer Added",
        "license_edited": "License Edited!",
        "email_in_use": "Email Already In Use",
        "profile_changes_success": "Profile Changes Successful!",
        "profile_changed": "Profile Changed!",
        "profile_changes_sent": "Profile Changes Sent For Approval!",
        "job_listing_deleted": "Job Listing Deleted",
        "applied_success": "Applied Successfully",
        "super_admin_logged_in": "Super Admin Logged In!",
        "admin_logged_in": "Admin Logged In!",
        "user_logged_in": "User Logged In!",
        "login_failed": "Could not Login!",
        "invalid_credentials": "Invalid Credentials",
        "card_saved": "Card Saved Successfully",
        "sign_up_canceled_card_required": "Sign Up Canceled: Card Required for Account Approval",
        "quote_sent": "Quote Request Sent",
        "employee_pass_changed": "Employee Password Changed!",
        "admin_ref_id_removed": "Admin Reference ID Removed!",
        "account_deleted": "Account Deleted",
        "job_submitted_for_review": "Job Submitted for Review",
        "invite_sent": "Invitation Sent",
        "notification_sent": "Notification Sent",
        "quote_price_sent": "Quote Price Sent!",
        "finish_sign_email_sent": "Finish Sign-up Email Sent",
        "cert_edited": "Certification Edited!",
        "pass_changed": "Password Changed!",
        "cert_created": "Certificate Created!",
        "admin_approved": "Admin Approved!",
        "admin_denied": "Admin Denied",
        "individual_approved": "Individual Approved",
        "individual_approve_fail": "Approve Individual Failed",
        "individual_deny": "Individual Denied",
        "individual_deny_fail": "Delete Individual Failed",
        "profile_change_approve": "Profile Changes Approved!",
        "profile_change_deny": "Profile Changes Denied!",
        "user_deny": "User Denied!",
        "cert_deny": "Certification Denied!",
        "user_changes_deny": "User Changes Denied!",
        "one_cert_image_required": "Please upload at least 1 picture of your license or certification",
        "double_check_file_ext": "Please double check that the correct image file was selected",
        "no_card_on_file": "Failed Card / No Card on File",
        "subscription_expired": "Subscription Expired",
        "invalid_email": "Invalid Email",
        "invalid_account_id": "Invalid Account ID",
        "invalid_message": "Notification Message Required",
        "incorrect_pass": "Incorrect Password",
        "please_enter_pass": "Please Enter Password",
        "quote_deleted": "Quote Deleted",
        "cert_deleted": "Certification Deleted!",
    },
    unsubscribe: unsubscribe.en,
}


export const sp = {
    "lang": "sp",
    "absorb_courses": {
        "language_instructions": "Ofrecemos cursos en inglés y español. Si desea ver e inscribirse en nuestros cursos de español, simplemente haga clic en el botón 'Ver en español' en la esquina superior derecha.",
        "disclaimer": "Todos nuestros cursos están disponibles tanto en inglés como en español, si completa un curso en un idioma, completarlo en el segundo idioma no contará para sus requisitos de Certificación CEU de Cal Fire Fitter.  Tenga en cuenta que no podemos emitir reembolsos simbólicos por tomar el mismo curso en dos idiomas diferentes"
    },
    absorb_courses: absorb_courses.sp,
    "add_cert": {
        "enter_cert_header": "Introduzca un nuevo certificado",
        "select_cert": "Seleccionar certificación",
        "cert_not_listed": "Si está enviando un certificado que no está en nuestra lista actual, envíe una copia del certificado a Info@acteducate.com y agregaremos el certificado a nuestra lista",
        "name": "Nombre",
        "ref_num": "Referencia #:",
        "classification": "Clasificación:",
        "rating": "Calificación:",
        "issued_date": "Fecha de emisión:",
        "state": "Estado:",
        "region": "Región:",
        "notes": "Notas:",
        "trainer_name": "Nombre del entrenador:",
        "re_certification_date": "Fecha de renovación:",
        "employee_id": "ID de empleado:",
        "eye_color": "Color de ojos:",
        "hair_color": "Color de cabello:",
        "weight": "Peso:",
        "height": "Altura:",
        "birth_date": "Fecha de nacimiento:",
        "license_number": "Licencia #:",
        "type": "Tipo:",
        "expiration_date": "Caducidad:",
        "image_header": "Imágenes de Certificado / Licencia",
        "image_front": "Frente: ",
        "image_back": "Volver: (opcional)",
        "submit": "Enviar"
    },
    "admin_add": {
        "new_ref": "Nuevo ID de referencia",
        "submit": "Enviar"
    },
    "admin_change": {
        "ref_num": "ReferenceId",
        "new_ref_num": "Nuevo ID de referencia",
        "submit": "Enviar",
        "to_individual": "Convertir a Individuo"
    },
    "admin_controls": {
        "act_header": "Acteducate.com",
        "admin_num": "Tu Número de Administrador Es: ",
        "add_emp": "Añadir empleado",
        "bulk_add": "Empleados de carga masiva",
        "pending_emp": "Empleados pendientes",
        "employee": "Empleado:",
        "employee_email": "Correo electrónico del empleado:",
        "approve": "Aprobar",
        "delete": "Suprimir",
        "pending_certs": "Certificaciones pendientes",
        "cert_header": "Certificación:",
        "emp_header": "Empleado:",
        "other_user_entries": "Campos de datos introducidos por el usuario:",
        "view_images": "Ver imagen(es)",
        "pending_emp_change_header": "Cambios pendientes en el perfil del empleado",
        "old_profile_header": "Información de perfil antiguo",
        "new_profile_header": "Nueva información de perfil"
    },
    "browse_courses": {
        "course_header": "Cursos",
        "pre_req": "Requisitos previos",
        "enroll": "Inscribirse",
        "edit": "Editar",
        "delete": "Suprimir",
        "course_edit": "Ediciones del curso",
        "name": "Nombre",
        "credits": "Créditos",
        "media": "Media-Link",
        "submit_edits": "Enviar ediciones",
        "create_course": "Crear curso",
        "add_pre_req": "Añadir un requisito previo a los cursos seleccionados",
        "select_pre_req": "Seleccionar requisitos previos",
        "add_course": "Añadir curso",
        "current_course": "Cursos actuales",
        "un_enroll": "Cancelar la inscripción"
    },
    "cal_card": cal_card.sp,
    "cert_update": {
        "lice_and_cert": "Nombre de licencia/certificación",
        "renewal_freq": "Frecuencia de renovación",
        "credits_required": "Créditos requeridos",
        "name": "Nombre",
        "ref_num": "Número de referencia",
        "classification": "Clasificación",
        "rating": "Calificación",
        "date_issued": "Fecha de expedición",
        "state": "Estado",
        "region": "Región",
        "notes": "Notas adicionales",
        "trainer_name": "Nombre del entrenador",
        "re_certification_date": "Fecha de recertificación",
        "employee_id": "ID de empleado",
        "eye_color": "Color de ojos",
        "hair_color": "Color de cabello",
        "weight": "Peso",
        "height": "Altura",
        "license_number": "Número de licencia",
        "type": "Tipo",
        "expiration_date": "Fecha de caducidad",
        "choose_fields": "Elegir campos adicionales",
        "submit": "Enviar"
    },
    "ceu_hours": {
        "external_requested": "CEU externas solicitadas",
        "ceus_requested": "CEU solicitados:",
        "name": "Nombre:",
        "email": "Correo electrónico:",
        "user_info": "Información del usuario",
        "view_request": "Ver solicitud"
    },
    "change_super_pass": {
        "change_pass": "Cambiar contraseña",
        "enter_new_pass": "Ingrese una nueva contraseña",
        "submit": "Enviar"
    },
    confirm_email: confirm_email.sp,
    "course_page": {
        "log_out": "Cerrar sesión",
        "act_header": "Acteducate.com"
    },
    "create_cert": {
        "lice_and_cert": "Licencia/Certificación",
        "ref_num": "Referencia #",
        "submit": "Enviar"
    },
    "drag_drop_page": {
        "act_header": "Acteducate.com",
        "back_to_admin": "Volver a los controles de administración",
        "download_template": "Descargar plantilla de entrada de usuario",
        "edit_search": "Editar campos de búsqueda",
        "drag_drop_here": "Arrastre y suelte la hoja de cálculo de datos de usuario completada aquí, o haga clic para navegar"
    },
    "edit_jobs": {
        "listing_header": "Detalles de la lista de trabajo",
        "industry": "Industria",
        "title": "Título",
        "description": "Descripción",
        "edit_listing": "Editar listado",
        "company_header": "Información de la empresa",
        "address": "Dirección",
        "email": "Correo electrónico",
        "phone": "Teléfono",
        "instructions": "Instrucciones (opcional)",
        "start_date": "Fecha de inicio",
        "end_date": "Fin (opcional)",
        "required_certs": "Certificaciones requeridas",
        "choose_certs": "Elija las certificaciones requeridas"
    },
    "edit_license": {
        "license_name": "Nombre de la licencia: ",
        "ref_num": "Referencia #:",
        "submit": "Enviar"
    },
    "edit_news": {
        "title": "Título",
        "content": "Contenido",
        "submit": "Enviar"
    },
    "edit_profile": {
        "first_name": "Nombre",
        "last_name": "Apellido",
        "phone": "Número de teléfono",
        "address_1": "Dirección",
        "address_2": "Apto, Bldg o Suite",
        "city": "Ciudad",
        "state": "Estado",
        "zip": "Zip",
        "language": "Idioma",
        "english": "Inglés",
        "spanish": "Español",
        "email": "Emails",
        "cc": "CC Emails (Separarlos por coma)",
        "submit": "Enviar"
    },
    "employee_list": {
        "employees": "Empleados",
        "sort_by": "Ordenar por:",
        "active": "Activo",
        "classification": "Clasificación",
        "date_of_hire": "Fecha de contratación",
        "no_employees": "No hay empleados que mostrar",
        "name": "Nombre",
        "email": "Correo electrónico",
        "certs": "Certificaciones",
        "controls": "Controles",
        "info": "Información",
        "no_certs": "No hay certificaciones que mostrar"
    },
    "feed": feed.sp,
    "field_exports": {
        "name": "Nombre...",
        "ref_num": "Número de referencia...",
        "issued_date": "Fecha de emisión...",
        "rating": "Calificación...",
        "state": "Estado...",
        "region": "Región...",
        "notes": "Notas complementarias...",
        "name_on_cert": "Nombre en el certificado...",
        "trainer_name": "Nombre del entrenador...",
        "re_certification_date": "Fecha de recertificación...",
        "employee_id": "ID de empleado...",
        "eye_color": "Color de ojos...",
        "hair_color": "Color de pelo...",
        "weight": "Peso...",
        "height": "Altura...",
        "birth_date": "Fecha de nacimiento...",
        "license_number": "Licencia #...",
        "status": "Estado...",
        "credits_required": "Créditos requeridos...",
        "classification": "Clasificación...",
        "renewal_frequency": "Frecuencia de renovación...",
        "credits_earned": "Créditos ganados...",
        "type": "Tipo...",
        "expiration_date": "Caducidad..."
    },
    "footer": {
        "contact": "Contacto",
        "stay_compliant": "Deje que ACT lo ayude a cumplir.",
        "more_info": "Para obtener más información, póngase en contacto con nosotros:",
        "email": "info@acteducate.com",
        "number": "(951) 221-3405",
        "addy": "P.O. Box 6482 Norco, CA 92860"
    },
    footer: footer.sp,
    "form_exports": {
        "create_job_header": "Crear Trabajo",
        "alt_business_name": "Nombre comercial (opcional)",
        "alt_business_name_placeholder": "El valor predeterminado es 'Acteducate.com'",
        "job_listing_header": "Detalles de la lista de trabajo",
        "industry": "Industria",
        "title": "Título",
        "description": "Descripción",
        "company_header": "Información de la empresa",
        "address": "Dirección",
        "email": "Correo electrónico",
        "phone": "Teléfono",
        "instructions": "Instrucciones (opcional)",
        "start_date": "Fecha de inicio",
        "end_date": "Fin (opcional)",
        "required_certs": "Certificaciones requeridas",
        "choose_certs": "Elija las certificaciones requeridas",
        "custom_options": "Opciones personalizadas",
        "customize_invite": "Personalizar las instrucciones de invitación de los solicitantes",
        "custom_apply_link": "Enlace para aplicar / entrevista",
        "applicant_instructions": "Instrucciones para el solicitante",
        "custom_questions": "Preguntas personalizadas para los solicitantes",
        "enter_custom_questions": "Ingrese preguntas personalizadas a continuación",
        "submit": "Enviar",
        "invite_employee": "Invitar al empleado a aplicar a ",
        "select_employee": "Seleccionar empleado para invitar",
        "send_invite": "Enviar invitación",
        "congratulations": "Felicidades, te han invitado a postularte en ",
        "job_title": "Cargo:",
        "job_industry": "Industria del trabajo:",
        "job_desc": "Descripción del puesto: ",
        "accept": "Aceptar",
        "deny": "Negar",
        "enter_industry": "Entrar en la industria",
        "enter_desc": "Introduzca la descripción..."
    },
    "functional_buttons": {
        "log_out": "Cerrar sesión",
        "indiv_sign_up": "Registro individual",
        "log_in": "Acceso",
        "change_language": "Ver en Inglés"
    },
    "apprentice": apprentice.sp,
    "apprentice_requests": {
        "name": "Nombre:",
        "business_name": "Nombre de la empresa:",
        "user_info": "Información del usuario",
        "pending_apprentices": "Aprendices pendientes",
        "download_form": "Descargar formulario",
        "approve_request": "Aprobar solicitud",
        "deny_request": "Denegar solicitud"
    },
    events: events.sp,
    "getting_started": {
        "cert_management_header": "Gestión de la certificación",
        "cert_management_desc": "¿Necesita ayuda para rastrear y mantener todas sus licencias y certificaciones?  ACT puede ayudarlo a administrarlos todos en un solo lugar.  ACT se especializa en la gestión de la certificación ca Fire Sprinkler Fitter y puede ayudarlo a mantenerse al día sobre todos los requisitos para mantener su licencia.  ¿Qué está incluido en la suscripción?\n (La cuenta debe estar activa durante no menos de 5 meses antes del período de renovación de Cal Fire para que no se le cobre una tarifa adicional, y la cuenta debe estar activa en el momento del período de renovación, a cualquier cuenta que no cumpla con estos requisitos se le cobrará una tarifa adicional por las tarifas de Cal Fire.  En el caso de que sea necesario cobrar tarifas adicionales o si hay algún cambio en las tarifas cobradas por Cal Fire, ACT notificará a todos los suscriptores antes de cobrar tarifas adicionales. )",
        "ca_sprinkler_fitter": "CA Fire Sprinkler Fitter - $ 45 por mes",
        "fitter_management_service": "CalFire Fitter Certification Management Services: ",
        "fitter_desc_0": "Te pondremos al día con Cal Fire y te ayudaremos a mantenerlo así.  Incluimos la cuota de renovación anual en nuestro precio de suscripción, para que no tengas que preocuparte por nada.  Se le cobrará un depósito si aún no ha renovado para el año de suscripción inicial.  Después de eso, simplemente mantenga su cuenta con ACT y déjenos encargarnos de ello. ",
        "fitter_desc_1": "Cursos de Educación Continua: ",
        "fitter_desc_2": "Con cada pago mensual recibirás 1 token para aplicar a cualquiera de nuestros cursos de educación continua.  Somos un proveedor aprobado por Cal Fire para la educación continua que se aplica a su certificación CalFire Fitter.  Realizaremos un seguimiento de todas sus horas CEU ganadas, incluso las obtenidas en otros lugares, y lo ayudaremos a completar los requisitos necesarios para mantener su certificación actualizada.   Si está atrasado en los créditos, podrá comprar tokens adicionales para aplicar a los cursos para ponerse al día",
        "license_and_cert": "Gestión de registros de licencias y certificaciones:",
        "license_and_cert_desc": "Mantenga todas sus licencias y certificaciones (como primeros auxilios / RCP, equipos, etc.) en un solo lugar, no se preocupe por perder su billetera.  Cargue copias de sus certificaciones, licencias, etc. en su perfil y tenga acceso a ellas en cualquier lugar.  También le ayudamos a administrar esas licencias para asegurarnos de que se mantiene actualizado",
        "news_and_updates": "Noticias y actualizaciones:",
        "news_and_updates_desc": "¡Noticias y actualizaciones exclusivas, sepa lo que está sucediendo en la industria que puede afectarlo!",
        "license_and_cert_desc_1": "Mantenga todas sus licencias y certificaciones en un solo lugar, no se preocupe por perder su billetera.  Cargue copias de sus certificaciones en su perfil y tenga acceso a ellas en cualquier lugar.  También le ayudamos a administrar esas licencias para asegurarnos de que se mantiene actualizado",
        "job_opportunities": "Oportunidades de trabajo",
        "job_opportunities_desc": "Oportunidades de trabajo exclusivas publicadas por la contratación de contratistas; buscar y aplicar todo en un solo lugar.",
        "help": "Ayuda cuando la necesites:",
        "help_desc": "¿Tienes una pregunta?  ¿Necesita orientación?  Póngase en contacto con ACT en info@acteducate.com para obtener ayuda",
        "ca_apprentice": "CA Fire Sprinkler Apprentice – $ 35 por mes",
        "ca_trainee": "CA Fire Sprinkler Trainee – $ 35 por mes",
        "questions": "¿Tiene alguna pregunta?  ¿Necesita orientación?  Póngase en contacto con ACT en info@acteducate.com para obtener ayuda.",
        "ca_other": "Otros – $ 25 por mes",
        "ca_other_desc": "Esta categoría es para aquellos que trabajan en la industria de rociadores contra incendios que necesitan ayuda para realizar un seguimiento de las certificaciones y licencias, pero no tienen certificaciones de Cal Fire.  Esta es una gran categoría para inspectores, ingenieros y otros",
        "approved_provider": "ACT es un proveedor aprobado de cursos de educación continua específicos para los requisitos de certificación de instalador de rociadores contra incendios de California.",
        "course_multi_lingual": "Los cursos están disponibles tanto en inglés como en español, los cursos en línea le permiten completar las horas requeridas a su conveniencia",
        "subscriber_courses": "¡Los suscriptores disfrutan de acceso a todos los cursos por menos de $ 13 por hora de capacitación! ",
        "purchasing_courses": "Todos los cursos se pueden comprar sin suscribirse a un precio ligeramente superior al de los suscriptores",
        "exclusive_news_and_updates": "Obtenga noticias y actualizaciones exclusivas aplicables a la industria de rociadores contra incendios, conozca y comprenda lo que puede estar afectándolo",
        "contribute_to_news": "¿Interesado en contribuir?  Envíanos un correo electrónico a info@acteducate.com",
        "manage_employees": "Gestione a todos sus empleados y sus certificaciones en un solo lugar.  Publique trabajos en nuestra página exclusiva de oportunidades de trabajo y seleccione a los solicitantes",
        "admin_controls": "Controles administrativos de la empresa",
        "safety_program": "Programa de seguridad",
        "update_safety_program": "Necesita ayuda para actualizar su programa de seguridad o simplemente necesita un recurso para hacer preguntas, ¡deje que ACT lo ayude!",
        "job_opportunity_header": "Oportunidades de trabajo exclusivas",
        "interested_in_posting_jobs": "¿Interesado en publicar una oportunidad de trabajo? Contáctanos en info@acteducate.com"
    },
    "header": {
        "admin_controls": "Controles de administración",
        "apprentice": "Aprendiz",
        "change_lang": "Ver en English",
        "courses": "Cursos",
        "home": "Hogar",
        "jobs": "Empleos",
        "learn_more": "Más información",
        "license_and_cert": "Perfil de Instalador",
        "log_in": "Acceso",
        "log_out": "Cerrar sesión",
        "news": "Noticias",
        "notifications": "Notificaciones",
        "profile": "Mi Cuenta",
        "settings": "Configuración",
        "sign_up": "Regístrate",
        "super_controls": "Super Controles",
        "rti_tracker": "Rastreador RTI",
        "access_denied": "Acceso Denegado: Suscripción Requerida",
        "events": "Eventos"
    },
    "home": {
        "mission_1": "Associated Compliance & Training (ACT) es una organización que se construyó sobre el principio de ayudar a quienes trabajan en la industria de rociadores contra incendios a cumplir con los requisitos de licencia. Brindamos soluciones y capacitación a través de nuestro Programa de Aprendizaje, Cursos de Educación Continua y Programas de Seguridad. También brindamos apoyo con la gestión de certificación a través de nuestro sistema Cal Fire Fitter Management. Somos el líder en la industria y estamos aquí para ayudarlo a usted o a su empresa a cumplir con los requisitos del estado",
        "mission_2": "ACT ahora ofrece un Programa de Capacitación de Aprendices de Instaladores de Rociadores contra Incendios aprobado por el gobierno federal para ayudar a satisfacer las necesidades de la industria. Todos los cursos se llevan a cabo a través de nuestra exclusiva escuela de aprendizaje en línea, ¡sin necesidad de gastar tiempo y dinero conduciendo a un aula para que pueda hacer todo el trabajo de su curso desde cualquier lugar! Nuestros cursos se ofrecen tanto en inglés como en español para ayudar a satisfacer las diversas necesidades de la industria. Para ayudar a que su empresa cumpla con las normas y para que pueda comenzar a inscribir aprendices lo antes posible, hemos hecho que el proceso de configuración sea rápido y fácil. Para más información, envíanos un correo electrónico a info@acteducate.com",
        "big_three": "Los Tres Grandes",
        "big_three_desc": "ACT apoya las necesidades más importantes de la industria. Obtenga más información sobre lo que ofrecemos y cómo ayudamos a las empresas a mantenerse en cumplimiento",
        "cert_management": "Cal Fire Fitter Certification Management",
        "cert_management_desc_1": "Proporcionamos renovación anual y soporte para nuevas solicitudes.",
        "cert_management_desc_2": "Tratar de mantenerse al día con el programa de certificación en constante cambio en California puede ser un desafío, así que no lo haga solo",
        "cert_management_desc_3": "ACT tiene el conocimiento y la experiencia para ayudar a conseguir y mantener a su equipo en cumplimiento",
        "cert_management_desc_4": "Trabajemos juntos para asegurarnos de que su negocio pueda seguir operando sin interrupción",
        "edu_courses": "Cursos de Educación Continua",
        "edu_courses_desc_1": "Somos un proveedor aprobado por Cal Fire para cursos de educación continua que cumplen con los requisitos de su certificación cal Fire Fitter.",
        "edu_courses_desc_2": "Todos nuestros cursos están disponibles tanto en inglés como en español para satisfacer las diversas necesidades de la industria.",
        "edu_courses_desc_3": "Contáctenos para comenzar u obtener respuestas a cualquier pregunta que pueda tener.",
        "edu_courses_desc_4": "Estamos aquí para ayudar",
        "apprentice_training": "Formación de aprendices",
        "apprentice_training_desc_1": "ACT tiene un programa de capacitación de aprendizaje de rociadores de incendio aprobado por el gobierno federal para ayudarlo a cumplir con los requisitos de cumplimiento en California. También tenemos el conocimiento y la experiencia para capacitar adecuadamente a su equipo",
        "apprentice_training_desc_2": "Nuestro programa consiste en las 7,000 horas requeridas en el trabajo y 510 horas de cursos y se puede completar en 3.5 años",
        "apprentice_training_desc_3": "Nuestros cursos son exclusivamente en línea y están disponibles tanto en inglés como en español para satisfacer las necesidades de su diverso equipo."
    },
    home: home.sp,
    "info": {
        "request_quote": "Solicitar presupuesto de la empresa",
        "mission_statement": "Associated Compliance & Training (ACT) es una organización que se construyó sobre el principio de resolver problemas y ayudar a quienes trabajan en la industria de rociadores contra incendios a cumplir con los requisitos de licencia, así como a proporcionar capacitación a través de nuestro programa de aprendizaje, cursos de educación continua y seguridad",
        "cert_management": "Gestión de la certificación",
        "need_help": "¿Necesita ayuda para rastrear y mantener todas sus licencias y certificaciones?  ACT puede ayudarlo a administrarlos todos en un solo lugar",
        "courses": "Cursos",
        "courses_description": "ACT es un proveedor aprobado de cursos de educación continua específicos para los requisitos de certificación de instalador de rociadores contra incendios de California.",
        "news_updates": "Noticias y actualizaciones",
        "news_updates_description": "Obtenga noticias y actualizaciones exclusivas aplicables a la industria de rociadores contra incendios, conozca y comprenda lo que puede estar afectándolo",
        "admin_controls": "Controles administrativos de la empresa",
        "admin_controls_description": "Gestione a todos sus empleados y sus certificaciones en un solo lugar.  Publique trabajos en nuestra página exclusiva de oportunidades de trabajo y seleccione a los solicitantes",
        "saftey_program": "Programa de seguridad",
        "saftey_program_description": "Necesita ayuda para actualizar su programa de seguridad o simplemente necesita un recurso para hacer preguntas, ¡deje que ACT lo ayude!",
        "job_opportunities": "Oportunidades de trabajo exclusivas",
        "job_opportunities_description": "¡Oportunidades de trabajo exclusivas para la industria de rociadores contra incendios!",
        "job_opportunities_description_2": "¿Interesado en publicar una oportunidad de trabajo?  Contáctanos en Info@acteducate.com",
        "contact": "Contacto",
        "contact_description": "Deje que ACT lo ayude a mantenerse en cumplimiento. Para obtener más información, póngase en contacto con nosotros en ",
        "contact_link": "info@acteducate.com"
    },
    "jobs": jobs.sp,
    "job_history": {
        "prior_job_header": "Historial de trabajo anterior",
        "job_title": "Cargo:",
        "job_industry": "Industria del trabajo:",
        "job_desc": "Descripción del puesto: ",
        "current_contractors": "Contratistas actuales:"
    },
    learn_more: learn_more.sp,
    "licenses": {
        "lice_and_cert": "Licencia/Certificación:",
        "ref_num": "Referencia #:",
        "issued_date": "Fecha de emisión:",
        "rating": "Calificación:",
        "state": "Estado:",
        "region": "Región:",
        "notes": "Notas:",
        "trainer_name": "Nombre del entrenador:",
        "re_certification_date": "Fecha de renovación:",
        "employee_id": "ID de empleado:",
        "eye_color": "Color de ojos:",
        "hair_color": "Color de cabello:",
        "weight": "Peso:",
        "height": "Altura:",
        "birth_date": "Fecha de nacimiento:",
        "license_number": "Licencia #:",
        "status": "Status:",
        "credits_required": "Créditos requeridos:",
        "classification": "Clasificación:",
        "renewal_frequency": "Frecuencia de renovación:",
        "credits_earned": "Créditos ganados:",
        "type": "Tipo:",
        "expiration_date": "Caducidad:"
    },
    "license_page": license_page.sp,
    "log_in": {
        "email": "Dirección de correo electrónico",
        "password": "Contraseña",
        "submit": "Enviar",
        "forgot_pass": "Olvidado tu contraseña",
        "forgot_back": "Atrás",
        "reset_email": "Por favor introduzca su correo electrónico",
        "reset_submit": "Restablecer la contraseña"
    },
    "navbar": {
        "home": "Hogar",
        "profile": "Perfil",
        "courses": "Cursos",
        "licenses": "Licencias y certificaciones",
        "jobs": "Oportunidades de trabajo",
        "feed": "Noticias",
        "settings": "Configuración",
        "notifications": "Notificaciones",
        "contact": "Contáctenos",
        "learn_more": "Más información"
    },
    "notifications": {
        "act_header": "Acteducate.com",
        "notification": "Notificaciones",
        "invite": "Invita"
    },
    "ojl_hours": {
        "hour_upload_requests": "Solicitudes de carga de horas OJL",
        "name": "Nombre:",
        "email": "Correo electrónico:",
        "hours_requested": "Horas solicitadas:",
        "user_info": "Información del usuario",
        "download_form": "Descargar formulario",
        "approve_request": "Aprobar solicitud",
        "deny_request": "Denegar solicitud"
    },
    "onboard": {
        "act_header": "Acteducate.com",
        "please_wait_message": "Por favor, no actualice la página. Si no se le redirige automáticamente a la página de inicio en 10 segundos, haga clic en el logotipo de ACT para ser redirigido a la página de inicio."
    },
    onboard: onboard.sp,
    "onboard_admin": {
        "business_name": "Nombre social",
        "your_email": "Su correo electrónico",
        "required": "* Requerido",
        "first_name": "Nombre",
        "last_name": "Apellido",
        "phone": "Número de teléfono",
        "address_1": "Dirección",
        "address_2": "Apto, Bldg o Suite",
        "city": "Ciudad",
        "state": "Estado",
        "zip": "Zip",
        "language": "Idioma",
        "english": "Inglés",
        "spanish": "Español",
        "password": "Contraseña",
        "password_2": "Volver a introducir la contraseña",
        "payment_header": "Detalles de pago",
        "enter_now": "Ingrese la Tarjeta Ahora",
        "enter_later": "Invitar al administrador a completar el registro",
        "click_accept": "Haga clic para aceptar"
    },
    "pending_quotes": {
        "quotes_pending": "Cotizaciones pendientes",
        "business_name": "Nombre de la empresa:",
        "email": "Correo electrónico:",
        "questions": "Preguntas:",
        "send_quote": "Enviar precio de cotización",
        "delete": "Solicitud de eliminación"
    },
    "placeholders": {
        "search": "Buscar",
        "enter_title": "Introducir contenido",
        "enter_content": "Introduzca el nombre",
        "enter_first_name": "Introduzca el apellido",
        "enter_last_name": "Ingrese el número de teléfono",
        "enter_phone_number": "Ingrese Apt o Suite",
        "enter_address": "Entra en la ciudad",
        "enter_address_2": "Entrar en el Estado",
        "enter_city": "Ingrese Zip",
        "enter_state": "Introduzca la contraseña",
        "enter_zip": "Ingrese la contraseña nuevamente",
        "password": "Ingrese el correo electrónico",
        "password_2": "Introduzca el nombre de la empresa",
        "enter_email": "Entra en la industria...",
        "enter_company_name": "Introduzca el título...",
        "enter_industry": "Introduzca la descripción...",
        "enter_desc": "Ingrese la dirección del primer día ...",
        "enter_instructions_for_new_hire": "Introduzca las instrucciones para la nueva contratación; uniforme esperado, qué llevar, etc...",
        "enter_hire_link": "(Opcional) Ingrese el enlace para solicitar / programar la entrevista",
        "enter_hire_instructions": "Ingrese instrucciones sobre cómo el invitado puede comunicarse con usted y / o programar una entrevista",
        "enter_new_password": "Ingrese una nueva contraseña",
        "enter_license_or_cert": "Ingrese Licencia/Certificación",
        "enter_ref_num": "Introduzca el número de referencia",
        "enter_renewal_freq": "Introduzca la frecuencia de renovación",
        "enter_required_credits": "Ingrese el número de créditos requeridos",
        "have_other_questions": "¿Tienes alguna otra pregunta?",
        "reason_for_deny": "Motivo de la negación"
    },
    "popup_exports": {
        "confirm_delete": "Confirmar eliminación",
        "confirm": "Confirmar",
        "confirm_denial": "Confirmar denegación",
        "apply": "Aplicar",
        "proceed_to_payment": "Proceder a la página de pago",
        "quote_price": "Precio de cotización:",
        "billed_monthly": " (Facturado Mensualmente)",
        "subscription_cost": "Coste de suscripción (anual)",
        "down_cost": "Costo de pago inicial (una sola vez)",
        "fitter_cost": "Coste por instalador (mensual)",
        "apprentice_cost": "Coste por aprendiz no ACT (mensual)",
        "act_apprentice_cost": "Coste por aprendiz de ACT (mensual)",
        "trainee_cost": "Coste por aprendiz (mensual)",
        "other_cost": "Costo por otro / no apto (mensual)",
        "email_quote": "Cotización por correo electrónico",
        "set_price": "Fijar precio",
        "id": "ID:",
        "name": "Nombre:",
        "address": "Dirección:",
        "classification": "Clasificación:",
        "phone": "Teléfono:",
        "language": "Idioma:",
        "data_applied": "Fecha de aplicación:",
        "active": "Activo",
        "inactive": "Inactivo",
        "certifications": "Certificaciones:",
        "no_certifications": "Sin certificaciones",
        "lice_and_cert": "Licencia/Certificación:",
        "ref_num": "Referencia #:",
        "issued_date": "Fecha de emisión:",
        "rating": "Calificación:",
        "state": "Estado:",
        "region": "Región:",
        "notes": "Notas:",
        "trainer_name": "Nombre del entrenador:",
        "renewal_date": "Fecha de renovación:",
        "employee_id": "ID de empleado:",
        "eye_color": "Color de ojos:",
        "hair_color": "Color de cabello:",
        "weight": "Peso:",
        "height": "Altura:",
        "birth_date": "Fecha de nacimiento:",
        "license_number": "Licencia #:",
        "status": "Estado:",
        "credits_required": "Créditos requeridos:",
        "renewal_frequency": "Frecuencia de renovación:",
        "credits_earned": "Créditos ganados:",
        "type": "Tipo:",
        "expiration": "Caducidad:",
        "reason_denied": "Razón denegada",
        "required_questions": "Preguntas del Solicitante"
    },
    "post_news": {
        "required": "* Requerido",
        "submit": "Enviar",
        "title": "Título",
        "image": "Imagen",
        "content": "Contenido"
    },
    "profile": profile.sp,
    "request_quote": {
        "request_header": "Solicite una cotización comercial",
        "business_name": "Nombre social",
        "required": "* Requerido",
        "email": "Correo electrónico",
        "optional": "(opcional)",
        "phone": "Teléfono",
        "questions": "Preguntas",
        "submit_quote": "Enviar solicitud de presupuesto"
    },
    "reset_password": {
        "change_pass_header": "Cambiar contraseña de empleados",
        "new_pass_header": "Ingrese una nueva contraseña",
        "new_pass_placeholder": "Ingrese la contraseña",
        "submit": "Enviar"
    },
    send_ceu_updates: send_ceu_updates.sp,
    "settings": {
        "act_header": "Acteducate.com",
        "reset_emp_pass": "Restablecer contraseña del empleado",
        "add_ID": "Agregar ID de empresa",
        "change_id": "Cambiar ID de empresa",
        "remove_id": "Eliminar ID de empresa",
        "change_pass": "Cambiar contraseña",
        "payment_info_header": "Información de pago",
        "plan": "Planifique: ",
        "amount": "Importe: ",
        "next_bill_date": "Próxima fecha de facturación: ",
        "cancel_plan": "Cancelar plan",
        "change_plan": "Plan de cambio",
        "payment_settings_header": "Configuración de pago",
        "payment_method": "Forma de pago: ",
        "update_payment_download_invoice": "Actualizar la configuración de pago y descargar facturas",
        "delete_account": "Eliminar cuenta"
    },
    "sign_up": {
        "required": "* Requerido",
        "your_email": "Su correo electrónico",
        "first_name": "Nombre",
        "last_name": "Apellido",
        "phone": "Número de teléfono",
        "address_1": "Dirección",
        "address_2": "Apto, Bldg o Suite",
        "city": "Ciudad",
        "state": "Estado",
        "zip": "Zip",
        "language": "Idioma",
        "english": "Inglés",
        "spanish": "Español",
        "password": "Contraseña",
        "password_2": "Volver a introducir la contraseña",
        "classification": "Clasificación",
        "select_account_type": "Seleccione el tipo de cuenta",
        "billing_frequency": "Frecuencia de facturación",
        "billing_frequency_select": "Seleccionar frecuencia de facturación",
        "monthly": "Mensual",
        "quarterly": "Trimestral",
        "bi_annually": "Semestralmente",
        "annually": "Anualmente",
        "have_you_renewed": "¿Ha renovado su licencia de Cal Fire este año?",
        "yes": "Sí",
        "no": "No",
        "selected_subs": "Suscripción seleccionada",
        "additional_deposits_required": "* Puede ser necesario un depósito adicional",
        "what_to_expect": "Qué esperar",
        "excited": "Estamos emocionados de que se registre como suscriptor de ACT. Se le enviará por correo electrónico un formulario de autorización de CA Fire, y nuestro equipo se comunicará con usted por correo electrónico dentro de las 48 horas para obtener más información para completar la configuración de su cuenta y asegurarse de que está recibiendo la asistencia que necesita. ",
        "excited_no_sub": "Estamos emocionados de que se inscriban y comiencen a tomar sus clases CEU aprobadas por Cal Fire con ACT. Después de hacer clic en enviar, será redirigido a nuestro portal de pago seguro donde su tarjeta se guardará en el archivo.  ",
        "no_charge_till_purchase": "A su tarjeta no se le cobrará ninguna tarifa de registro o suscripción. Se guardará con Stripe, nuestro proveedor de pago seguro para que no tenga que ingresar su tarjeta cada vez que desee comprar tokens",
        "no_charge_till_approved": "Su tarjeta no será cargada hasta que su cuenta sea aprobada por nuestro equipo.",
        "click_accept": "Haga clic para aceptar",
        "submit": "Enviar",
        "hide_policy": "Ocultar política de privacidad",
        "privacy_policy": "Política de privacidad",
        "hide_terms": "Ocultar términos y condiciones",
        "terms_and_conditions": "Términos y condiciones",
        "no_changes_selected": "No se seleccionaron cambios en la facturación",
        "payment_failed": "Pago fallido",
        "deactivate": "Desactivar",
        "update_card": "Actualizar tarjeta",
        "amount_of": "Importe de",
        "hours_whole_number": "Horas (números enteros)",
        "credits_decimals": "Créditos (decimales)",
        "edit_hours": "Editar horas:",
        "save": "Guardar",
        "delete": "Suprimir",
        "view_pdf": "Ver PDF",
        "view_image": "Ver imagen"
    },
    sign_up: sign_up.sp,
    "sign_up_admin": {
        "company_name": "Nombre de la empresa",
        "your_email": "Su correo electrónico",
        "first_name": "Nombre",
        "last_name": "Apellido",
        "phone": "Número de teléfono",
        "address_1": "Dirección",
        "address_2": "Apto, Bldg o Suite",
        "city": "Ciudad",
        "state": "Estado",
        "zip": "Zip",
        "language": "Idioma",
        "english": "Inglés",
        "spanish": "Español",
        "password": "Contraseña",
        "password_2": "Volver a introducir la contraseña",
        "expectations": "Qué esperar",
        "we_are_excited": "Estamos emocionados de que se registre como suscriptor de ACT. Se le enviará por correo electrónico un formulario de autorización de CA Fire, y nuestro equipo se comunicará con usted por correo electrónico dentro de las 48 horas para obtener más información para completar la configuración de su cuenta y asegurarse de que está recibiendo la asistencia que necesita. Su tarjeta no será cargada hasta que su cuenta sea aprobada por nuestro equipo.",
        "click_accept": "Haga clic para aceptar",
        "submit": "Enviar"
    },
    "sign_up_employee": {
        "ref_num": "Número de referencia",
        "required": "* Requerido",
        "email": "Su correo electrónico",
        "account_type": "Tipo de cuenta",
        "select_account_type": "Seleccione el tipo de cuenta",
        "first_name": "Nombre",
        "last_name": "Apellido",
        "phone": "Número de teléfono",
        "address_1": "Dirección",
        "address_2": "Apto, Bldg o Suite",
        "city": "Ciudad",
        "state": "Estado",
        "zip": "Zip",
        "language": "Idioma",
        "english": "Inglés",
        "spanish": "Español",
        "password": "Contraseña",
        "password_2": "Volver a introducir la contraseña",
        "click_accept": "Haga clic para aceptar",
        "submit": "Enviar"
    },
    "sign_up_admin_employee": {
        "business_id": "ID de empresa",
        "emp_email": "Correo electrónico del empleado",
        "first_name": "Nombre",
        "last_name": "Apellido",
        "phone": "Número de teléfono",
        "address_1": "Dirección",
        "address_2": "Apto, Bldg o Suite",
        "city": "Ciudad",
        "state": "Estado",
        "zip": "Zip",
        "language": "Idioma",
        "english": "Inglés",
        "spanish": "Español",
        "password": "Contraseña",
        "password_2": "Volver a introducir la contraseña",
        "click_accept": "Haga clic para aceptar",
        "submit": "Enviar"
    },
    "super_certs": {
        "submit_new_cert": "Presentar una nueva certificación",
        "lice_and_cert": "Licencia/Certificación:",
        "cal_fire_reg_card": "Tarjeta de registro de Cal Fire Fitter",
        "issued_date": "Fecha de emisión:",
        "ceu_credit_period": "Período de crédito CEU",
        "status": "Estado"
    },
    "super_cert_upload": {
        "title": "Título",
        "required": "* Requerido",
        "not_required": "No requerido",
        "renewal_frequency": "Frecuencia de renovación",
        "credits_required": "Créditos requeridos",
        "required_details": "Detalles requeridos",
        "name": "Nombre",
        "ref_num": "Número de referencia",
        "classification": "Clasificación",
        "rating": "Calificación",
        "date_issued": "Fecha de expedición",
        "state": "Estado",
        "region": "Región",
        "notes": "Notas adicionales",
        "trainer_name": "Nombre del entrenador",
        "re_certification_date": "Fecha de recertificación",
        "employee_id": "ID de empleado",
        "eye_color": "Color de ojos",
        "hair_color": "Color de cabello:",
        "weight": "Peso:",
        "height": "Altura:",
        "birth_date": "Fecha de nacimiento:",
        "license_number": "Licencia #:",
        "type": "Tipo:",
        "expiration_date": "Caducidad:",
        "choose_fields": "Elegir campos adicionales",
        "submit": "Enviar"
    },
    "super_functionality": {
        "hide_ceu_hour_submittal": "Ocultar Forma de CEU",
        "cal_card_required": "(Se requiere tarjeta Cal)",
        "add_emp_to_admin": "Agregar empleado al administrador",
        "add_ceu_hours": "Añadir horas CEU",
        "add_act_app": "Añadir aprendiz de ACT",
        "send_ceu_update_emails": "Enviar Emails de CEU",
        "download_stripe_audit": "Auditoría de Stripe",
        "view_calendly": "Ver Calendly para Aprendices",
        "act_header": "Acteducate.com",
        "active_subs": "Suscripciones activas",
        "hide_edit": "Ocultar edición",
        "name": "Nombre:",
        "email": "Correo electrónico:",
        "info": "Información",
        "certs": "Certificaciones",
        "admins": "Socio de Capacitación en Cumplimiento",
        "tokens": "Tokens:",
        "upload_cert": "Subir certificado",
        "cert_name": "Nombre del certificado:",
        "edit": "Editar",
        "delete": "Suprimir",
        "no_credit": "Sin crédito",
        "pending_indv": "Personas pendientes",
        "selected_classification": "Clasificación seleccionada:",
        "price_and_freq": "Precio y frecuencia:",
        "has_renewed": "Ha renovado:",
        "no_card_saved": "NO HAY TARJETA REGISTRADA",
        "no_card_instructions": "Por favor, póngase en contacto con este usuario y dígale que inicie sesión. Serán redirigidos a stripe, y después de que pongan una tarjeta válida, este mensaje desaparecerá y la cuenta estará lista para su aprobación",
        "approve": "Aprobar",
        "approve_header": "Aprobar Instalador",
        "approving": "Aprobatorio: ",
        "set_down_price": "Depósito",
        "approve_no_down": "Aprobar sin pago inicial",
        "pending_admin": "Socio de Capacitación en Cumplimiento Pendientes",
        "sign_up_admin": "Regístrese Admin / Business",
        "admin": "Admin:",
        "admin_email": "Correo electrónico del administrador:",
        "pending_admin_changes": "Cambios de Socio de Capacitación en Cumplimiento Pendientes",
        "old_profile_header": "Información de perfil antiguo",
        "new_profile_header": "Nueva información de perfil",
        "deny": "Negar",
        "pending_certs": "Certificaciones pendientes",
        "cert": "Certificación:",
        "user": "Usuario:",
        "user_info": "Información del usuario",
        "cert_info": "Cert Info",
        "view_image": "Ver imagen",
        "jobs_pending": "Puestos de trabajo pendientes",
        "reason_for_change": "Trabajo:",
        "job": "Industria:",
        "industry": "Editar administrador",
        "edit_admin": "Opciones de administración",
        "admin_options": "Asociación de Aprendices",
        "custom_priced_tokens": "Editar estructura de precios",
        "edit_pricing_structure": "Muévete todo a la historia",
        "move_all_history": "Socio de formación en cumplimiento",
        "compliance_training_partner": "Descripción:",
        "description": "Empresa:",
        "company": "Autor",
        "author": "Hacer correcciones",
        "make_corrections": "Razones de denegación",
        "denial_reasons": "Motivo:",
        "reason": "Categoría",
        "category": "Motivo del cambio:",
        "external_ceu_hours": "Horario CEU externo",
        "hours_whole_numbers": "Horas (Números)",
        "credits_decimals": "Creditos (Decimales)",
        "deactivate": "Desactivar",
        "update_card": "Actualizar tarjeta",
        "billing_frequency": "Frecuencia de Facturación:",
        "company": "Compañía:",
        "has_apprentice": "Socio Aprendiz:",
        "fitter_count": "Instaladores:",
        "apprentice_count": "Aprendices:",
        "business_number": "Numero de Negocios:"
    },
    "super_navbar": {
        "home": "Hogar",
        "learn_more": "Más información",
        "available_courses": "Cursos disponibles",
        "job_opportunities": "Oportunidades de trabajo",
        "profile": "Perfil",
        "add_cert": "Añadir certificaciones"
    },
    "table_display": {
        "review": "Revisión",
        "finalize": "Revisar y finalizar",
        "delete": "Eliminar seleccionado",
        "create": "Crear seleccionado"
    },
    "toast": {
        "user_approved": "¡Aprobado por el usuario!",
        "cert_approved": "¡Certificación aprobada!",
        "changes_approved": "¡Cambios de usuario aprobados!",
        "news_edited_success": "News Post editado con éxito",
        "news_posted": "Noticia publicada",
        "account_pending": "¡Cuenta pendiente!",
        "please_enter_all_required": "Por favor, introduzca todos los campos * obligatorios",
        "account_created": "¡Cuenta creada!",
        "cert_request_sent": "¡Solicitud de certificación enviada!",
        "double_check_inputs": "Algo salió mal, por favor verifique todas las entradas e inténtelo de nuevo",
        "employer_added": "Empleador Agregado Exitosamente",
        "license_edited": "¡Licencia editada!",
        "email_in_use": "Correo electrónico ya en uso",
        "profile_changes_success": "¡Cambios de perfil exitosos!",
        "profile_changed": "¡Perfil cambiado!",
        "profile_changes_sent": "¡Cambios de perfil enviados para su aprobación!",
        "job_listing_deleted": "Listado de trabajo eliminado",
        "applied_success": "Aplicado con éxito",
        "super_admin_logged_in": "¡Súper administrador conectado!",
        "admin_logged_in": "¡Admin ha iniciado sesión!",
        "user_logged_in": "¡Usuario que ha iniciado sesión!",
        "login_failed": "¡No se pudo iniciar sesión!",
        "invalid_credentials": "Credenciales no válidas",
        "card_saved": "Tarjeta guardada con éxito",
        "sign_up_canceled_card_required": "Regístrese cancelado: Tarjeta requerida para la aprobación de la cuenta",
        "quote_sent": "Solicitud de cotización enviada",
        "employee_pass_changed": "¡Contraseña de empleado cambiada!",
        "admin_ref_id_removed": "¡Id. de referencia de administrador eliminado!",
        "account_deleted": "Cuenta eliminada",
        "job_submitted_for_review": "Trabajo enviado para revisión",
        "invite_sent": "Invitación enviada",
        "notification_sent": "Notificación enviada",
        "quote_price_sent": "¡Precio de cotización enviado!",
        "finish_sign_email_sent": "Finalizar el correo electrónico de registro enviado",
        "cert_edited": "¡Certificación editada!",
        "pass_changed": "¡Contraseña cambiada!",
        "cert_created": "¡Certificado creado!",
        "admin_approved": "¡Aprobado por el administrador!",
        "admin_denied": "Administrador denegado",
        "individual_approved": "Aprobado individualmente",
        "individual_approve_fail": "Aprobar individuo fallido",
        "individual_deny": "Individuo denegado",
        "individual_deny_fail": "Error al eliminar individuo",
        "profile_change_approve": "¡Cambios de perfil aprobados!",
        "profile_change_deny": "¡Cambios de perfil denegados!",
        "user_deny": "¡Usuario denegado!",
        "cert_deny": "¡Certificación denegada!",
        "user_changes_deny": "¡Cambios de usuario denegados!",
        "one_cert_image_required": "Por favor, cargue al menos 1 foto de su licencia o certificación",
        "double_check_file_ext": "Por favor, verifique que se seleccionó el archivo de imagen correcto",
        "no_card_on_file": "Tarjeta fallida / Sin tarjeta en el archivo",
        "subscription_expired": "Suscripción caducada",
        "invalid_email": "Correo electrónico no válido",
        "invalid_account_id": "ID de cuenta no válido",
        "invalid_message": "Mensaje de notificación requerido",
        "incorrect_pass": "Contraseña incorrecta",
        "please_enter_pass": "Por favor, introduzca la contraseña",
        "quote_deleted": "Cita eliminada",
        "cert_deleted": "¡Certificación eliminada!"
    },
    unsubscribe: unsubscribe.sp,
}