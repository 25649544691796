import React, { useState, useContext } from 'react'
import axios from "axios"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { useStripe } from '@stripe/react-stripe-js';
import { Validator } from '../../exportcomponents/ValidatorExports';
import './auth.css'

import UserContext from '../../context/UserContext';
import LangContext from '../../context/LangContext';
import { en, sp } from '../../context/Translations';

const LogIn = () => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const { userData, setUserData } = useContext(UserContext);
    const { langData, setLangData } = useContext(LangContext);
    const [buttonDisable, setButtonDisable] = useState({});
    const [form, setForm] = useState({});
    const [showForgot, setShowForgot] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [showCardFail, setShowCardFail] = useState({ account_id: '', paid: true });
    const [showPassword, setShowPassword] = useState(false)

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const resetByEmail = async (e) => {
        e.preventDefault();
        const isValidEmail = await axios.post(`${process.env.REACT_APP_API_URL}/account/check-email-duplicate`, { email: forgotEmail });
        if (isValidEmail.data == false) {
            return toast.error("Sorry, It looks like there's no account associated with that email");
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}/check/send/email`, {
                to: forgotEmail,
                subject: "ACT password reset",
                textBody: `There was a request to reset the password for ${forgotEmail}. If you did not send this request, please ignore this email\n\nClick here to reset your password:\n${process.env.REACT_APP_VIEW_URL}/onboard?reset_pass=${forgotEmail}`
            }).then((res) => {
                toast.success("Password reset email sent, please check your inbox!");
            }).catch((err) => {
                toast.error(err.message);
            });
                
        }
    }

    const onRedirectToStripe = async () => {
        // This is getting back the customer id from stripe, 
        // but may fail if that user doesn't line up anymore, 
        // and then they can't update their payment because the account_id 
        // never makes it back to the /payment/create-checkout-session call
        const customerData = await axios.get(`${process.env.REACT_APP_API_URL}/payment/id/${showCardFail?.account_id}`);
        console.log("SESSION???????\n", customerData)
        const session = await axios.post(`${process.env.REACT_APP_API_URL}/payment/create-checkout-session`, { 
            customer_id: customerData.data.customerId,
            account_id: showCardFail.account_id,
            mode: 'subscription', 
            payment_method_types: ['card'], 
            price: customerData.data.subscription_price, 
            success_url: `${process.env.REACT_APP_VIEW_URL}/onboard?pending_payment=${showCardFail.account_id}` 
        });
        return stripe.redirectToCheckout({
            sessionId: session.data.id
        });
    }

    const submit = async (e) => {
        e.preventDefault();

        setButtonDisable({ submit: true });
        setTimeout(() => setButtonDisable({ submit: false }), 1000);

        try {
            const valid = Validator(form, ['email', 'password']);
            if (valid.length == 0) {
                const loginRes = await axios.post(`${process.env.REACT_APP_API_URL}/account/login`, {
                    email: form.email,
                    password: form.password
                });

                // console.log(loginRes.data);

                if (loginRes) {
                    if (loginRes.data.pending_payment == true) {
                        // Kevin is my cat
                        // console.log('Whenever I Sneeze');
                        toast.error(langData.toast.no_card_on_file);
                        setShowCardFail({
                            account_id: loginRes.data.userId,
                            paid: false
                        });
                        return null
                    } else if (loginRes.data.admin_pending_payment == true) {
                        toast.error(`Account Suspended for Non-Payment`);
                        setShowCardFail({
                            admin_paid: false,
                            business_name: loginRes.data.business_name
                        });
                        return null
                    }
                    if (!loginRes.data.user.isPaid && !loginRes.data.user.isSuper) {
                        // He gives me allergiez
                        // console.log('Kevin meows VERY loudly');
                        if (!loginRes.data.user.isApproved) {
                            return toast.error('Account Not Yet Approved')
                        } else {
                            console.log("AM i DYING HERE OR AM i DYING HERE? ")
                            toast.error(langData.toast.subscription_expired);
                            const customerData = await axios.get(`${process.env.REACT_APP_API_URL}/payment/id/${loginRes.data.user.userId}`);
                            const session = await axios.post(`${process.env.REACT_APP_API_URL}/payment/create-checkout-session`, { 
                                account_id: loginRes?.data?.user?.userId, customer_id: customerData.data.customerId, mode: 'subscription', payment_method_types: ['card'], price: customerData.data.subscription_price });
                            return stripe.redirectToCheckout({
                                sessionId: session.data.id
                            });
                        }
                    }

                    setUserData({
                        token: loginRes.data.token,
                        user: loginRes.data.user,
                        userId: loginRes.data.user.userId
                    });
                    
                    const lang_dict = { English: en, Spanish: sp };
                    if (Object.keys(lang_dict).includes(loginRes.data.language)){
                        // console.log(loginRes.data.language);
                        setLangData(lang_dict[loginRes.data.language]);
                    } else {
                        // console.log("Other Language");
                    }

                    // console.log(loginRes.data.user)
                    // console.log(`Is Admin?  ${loginRes.data.user.isAdmin}`);
                    // console.log(`Is Super?  ${loginRes.data.user.isSuper}`);

                    const id = loginRes.data.user.userId;
                    localStorage.setItem('auth-token', loginRes.data.token);
                    localStorage.removeItem('event-email')

                    if (loginRes.data.user.isSuper) {
                        navigate(`/super`);
                        toast.success(langData.toast.super_admin_logged_in);
                    } else if (loginRes.data.user.isAdmin) {
                        navigate(`/admin`);
                        toast.success(langData.toast.admin_logged_in);
                    } else {
                        navigate(`/`);
                        toast.success(langData.toast.user_logged_in);
                    }

                } else {
                    toast.error(langData.toast.login_failed)
                }
            } else {
                toast.error(langData.toast.please_enter_all_required);
            }            
        } catch (err) {
            console.log(err);
            if (err.response) {
                if (err.response.status == 423) {
                    toast.error("Your account is pending approval of a classification change. Please check for an email from actedu.notification@gmail.com")
                } else {
                    toast.error(langData.toast.invalid_credentials);
                }
            } else {
                toast.error("There was an issue connecting to the server, please try again later")
            }
        }
    };

    return (
        <div className='auth-modal card no-scroll'>
            <div className='card-body'>
            {!showForgot ? (
                <>
                    {showCardFail.paid == false ? (
                        <div className='card-fail'>
                            <b className="mb-5">
                                {langData.sign_up.issue_charging_card}
                            </b>
                            <p className="my-3">
                                {langData.sign_up.stripe_redirect}
                            </p>
                            <button className="w-100" onClick={onRedirectToStripe}>
                                {langData.sign_up.update_card}
                            </button>
                        </div>
                    ) : showCardFail.admin_paid == false ? (
                            <div className='card-fail no-button'>
                                <b className="my-5">
                                    Business Account Suspended
                                </b>
                                <p className="mt-3">
                                    The {showCardFail.business_name} ACT account is suspended. Please notify your administrator to regain access to your account and courses.
                                </p>
                            </div>
                        ) : ( <form onSubmit={submit}>
                            <h1 className='h1'>
                                {langData.sign_up.login}
                            </h1>
                            <div className='d-flex mb-2'>
                                <div className='d-grid text-left'>
                                    <span className="sign-up-label">
                                        {langData.sign_up.email}
                                    </span>
                                    <input 
                                        className='sign-up-input'
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        onChange={onChange}/>
                                </div>
                            </div>
                            <div className='d-flex mb-2'>
                                <div className='d-grid text-left'>
                                    <span className="sign-up-label">
                                        {langData.sign_up.password}
                                    </span>
                                    <input
                                        className='sign-up-input'
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        placeholder='Password'
                                        onChange={onChange}/>
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="email">{langData.log_in.email}</label>
                                <input
                                type="email"
                                name="email"
                                onChange={onChange}
                                    placeholder={langData.placeholders.enter_email}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">{langData.log_in.password}</label>
                                <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                name="password"
                                onChange={onChange}
                                    placeholder={langData.placeholders.password}
                                    />
                                </div> */}
                            <div className="pass-controls d-flex justify-content-between align-items-center my-3">
                                <div className='d-flex align-items-center ml-1 my-1'>
                                    <input className="sign-up-check" type="checkbox" onInput={(e) => setShowPassword(e.target.checked)}/>
                                    <span className='sign-up-check-label'>{langData.sign_up.show_pass}</span>
                                </div>
                                <a className="text-primary" onClick={() => setShowForgot(true)}>{langData.sign_up.forgot_pass}</a>
                            </div>
                            <div className='mt-4'>
                                <button className="login-button" disabled={buttonDisable.submit} type="submit">
                                    {langData.sign_up.login}
                                </button>
                            </div>
                        </form>
                    )}
                </>
            ) : (
                <>
                    <div className='d-flex align-items-center justify-content-between forgot-header'>
                        <h3 className='h3'>
                            {langData.sign_up.forgot_form}
                        </h3>
                        <a onClick={() => setShowForgot(false)}>
                            {langData.sign_up.go_back}
                        </a>
                    </div>
                    <div className='d-flex mb-2'>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.email}
                            </span>
                            <input 
                                className='sign-up-input'
                                placeholder="Email"
                                onChange={(e) => setForgotEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className='mt-5'>
                        <button className="login-button" disabled={buttonDisable.submit} onClick={resetByEmail} type="submit">
                            {langData.sign_up.send_forgot}
                        </button>
                    </div>
                </>
                // <form className="form-background">
                //     <div className="form-group">
                //         <div>
                //         <svg width="18" height="9" version="1.1" viewBox="0 0 172.66 110.41" xmlns="http://www.w3.org/2000/svg">
                //                 <g transform="translate(-29.234 -88.775)">
                //                     <g fill="none" stroke="#000" stroke-linecap="round">
                //                         <path d="m195.61 142.24-159.7 0.1755" stroke-width="12.565"/>
                //                         <path d="m87.084 95.207-51.168 47.213" stroke-width="12.865"/>
                //                         <path d="m86.082 192.5-50.165-50.083" stroke-width="13.365"/>
                //                     </g>
                //                 </g>
                //             </svg>
                //             <a className="text-primary" onClick={() => setShowForgot(false)}>{langData.log_in.forgot_back}</a>
                //         </div>
                //         <strong className="mt-2">{langData.log_in.reset_email}</strong>
                //         <input
                //             type="text"
                //             placeholder="Email"
                //             onChange={(e) => setForgotEmail(e.target.value)}
                //             className="form-control mt-3"
                //         />
                //     </div>
                //     <div className="form-group mt-3">
                //         <button
                //             onClick={(e) => resetByEmail(e)}
                //             className="btn btn-primary"
                //             >
                //             {langData.log_in.reset_submit}
                //         </button>
                //     </div>
                // </form>
            )}
            </div>
        </div>
    )
}

export default LogIn
