import { useState } from "react"

const DeactivateNotes = ({user, deactivateCallback}) => {
    const [notes, setNotes] = useState('')

    return (
        <div className="form-background wide-popup">
            <h3 className="h3 mb-0">Deactivation Notes</h3>
            <p className="my-2">Deactivating: {user.first_name} {user.last_name}</p>
            <textarea onKeyDown={(e) => setNotes(e?.target?.value)} className="w-100 px-2 py-1" placeholder="Reason for deactivation..." />
            <button onClick={(e) => deactivateCallback(e, notes)} className="w-100 mt-2">Confirm</button>
        </div>
    )
}

export default DeactivateNotes