import React, { isValidElement, useState, useEffect, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { useStripe } from '@stripe/react-stripe-js';
import axios from "axios"
import { toast } from 'react-toastify'
import "../components/styles.css"
import "./super.css";
import LangContext from '../context/LangContext';

const EditAdminProfile = ({ id }) => {
    const [form, setForm] = useState({});
    const { langData } = useContext(LangContext);
	const [adminStatus, setAdminStatus] = useState({})
    const navigate = useNavigate()

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(form)
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            // console.log(form);
            if (form.email) {
                const email_is_unique = await axios.post(`${process.env.REACT_APP_API_URL}/account/check-email-duplicate`, { email: form.email });
                // console.log(email_is_unique);
                if (email_is_unique.data) {
                    return toast.error(langData.toast.email_in_use);
                }
            }

            await axios.put(`${process.env.REACT_APP_API_URL}/account/super/${id}`, form);
            // navigate('/')
            // navigate('/super')
            toast.success(langData.toast.profile_changes_sent);

        } catch (err) {
            toast.error(err.message.data);
        }
    };

    async function asyncSetupEditAdmin() {
        const accountInfo = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${id}`)
        
        setAdminStatus(accountInfo.data)
    }

    useEffect(() => {
        asyncSetupEditAdmin()
    }, [])

    return (
        <div className="form-background popup-scrollable">
            <h2 className="h2 mb-2">Edit CTP Admin</h2>
            <form>
                {adminStatus.first_name ? (
                    <div className="mb-2">
                    <div>
                        <label htmlFor="name">{langData.edit_profile.first_name}</label>
                        <input
                            type="first_name"
                            className=""
                            name="first_name"
                            onChange={onChange}
                            placeholder={adminStatus.first_name}
                        />
                    </div>
                    </div>
                ) : (
                    <div className="mb-2">
                        <div>
                            <label htmlFor="name">{langData.edit_profile.first_name}</label>
                            <input
                                type="first_name"
                                className=""
                                name="first_name"
                                onChange={onChange}
                                placeholder={adminStatus.first_name}
                            />
                        </div>
                    </div>
                )}
                
                <div className="mb-2">
                    <label htmlFor="name">{langData.edit_profile.last_name}</label>
                    <input
                        type="last_name"
                        className=""
                        name="last_name"
                        onChange={onChange}
                        placeholder={adminStatus.last_name}
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="password">{langData.edit_profile.address_1}</label>
                    <input
                        type="address_1"
                        className=""
                        name="address_1"
                        onChange={onChange}
                        placeholder={adminStatus.address_1}
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="password">{langData.edit_profile.address_2}</label>
                    <input
                        type="address_2"
                        className=""
                        name="address_2"
                        onChange={onChange}
                        placeholder={adminStatus.address_2}
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="password">{langData.edit_profile.city}</label>
                    <input
                        type="city"
                        className=""
                        name="city"
                        onChange={onChange}
                        placeholder={adminStatus.city}
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="password">{langData.edit_profile.state}</label>
                    <input
                        type="state"
                        className=""
                        name="state"
                        onChange={onChange}
                        placeholder={adminStatus.state}
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="password">{langData.edit_profile.zip}</label>
                    <input
                        type="zip"
                        className=""
                        name="zip"
                        onChange={onChange}
                        placeholder={adminStatus.zip}
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="displayName">{langData.edit_profile.language}</label>
                    <select
                        className=""
                        name="language"
                        aria-label="Default select example"
                        onChange={onChange}
                    >
                        <option value="English">{langData.edit_profile.english}</option>
                        <option value="Spanish">{langData.edit_profile.spanish}</option>
                    </select>
                </div>
                <div className="mb-2">
                    <label htmlFor="phone">{langData.edit_profile.phone}</label>
                    <input
                        type="phone"
                        className=""
                        name="phone"
                        onChange={onChange}
                        placeholder={adminStatus.phone}
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="email">{langData.edit_profile.email}</label>
                    <input
                        type="email"
                        className=""
                        name="email"
                        onChange={onChange}
                        placeholder={adminStatus.email}
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="cc">{langData.edit_profile.cc}</label>
                    <input
                        type="text"
                        className=""
                        name="cc"
                        onChange={onChange}
                        placeholder={adminStatus.cc}
                    />
                </div>
                <div>
                    <button onClick={submit} type="submit" className="btn btn-primary submit mt-2 w-100">
                        {langData.edit_profile.submit}
                    </button>
                </div>
            </form>
        </div>
    )
}


export default EditAdminProfile