import { useEffect, useState, useRef } from "react"
import { toast } from "react-toastify"
import { live_data, test_data } from './RTITrackerTestData'
import './RTITracker.css'
import axios from 'axios'
import RTIContextMenu from "./RTIContextMenu"

export default () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [courses, setCourses] = useState({})
    const [enrollments, setEnrollments] = useState([])
    const [contextMenu, setContextMenu] = useState({ toggled: false, position: { x: 0, y: 0 } })
    const [rtiChanges, setRtiChanges] = useState([])
    const contextMenuRef = useRef(null)

    const course_colors = {
        '0': '#ccccff',
        '1': '#f59093',
        '2': '#ebeb8d',
        '3': '#ff8ce2',
        '4': '#77c4e0',
        '5': '#f7da81',
        '6': '#91f2ad',
    }

    function onEnrollmentContextMenu(e, selected) {
        e.preventDefault()
        console.log(e.pageX)
        console.log(e.pageY)
        setContextMenu({ toggled: true, selected, position: { x: e.pageX, y: e.pageY } })
    }

    function resetRtiContextMenu() {
        setContextMenu({ toggled: false, position: { x: 0, y: 0 } })
    }

    function rtiContextMenuLeftClick(e) {
        console.log("rtiContextMenuLeftClick IS A MONSTROSITY of a name.")
        if (!contextMenuRef?.current?.contains(e.target)) {
            resetRtiContextMenu()
        }
    }

    function parseIndexus({ enrollee_i, level_i, position_i }) {
        return {
            enrollee_i: typeof enrollee_i == 'string' ? parseInt(enrollee_i) : enrollee_i,
            level_i: typeof level_i == 'string' ? parseInt(level_i) : level_i,
            position_i: typeof position_i == 'string' ? parseInt(position_i) : position_i
        }
    }

    function rtiColorChange(color, status) {
        console.log(color, typeof status)
        let updated_enrollments = enrollments
        const { enrollee_i, level_i, position_i } = parseIndexus(contextMenu.selected)
        updated_enrollments[enrollee_i].levels = updated_enrollments[enrollee_i].levels || {}
        updated_enrollments[enrollee_i].levels[level_i] = updated_enrollments[enrollee_i].levels[level_i] || {}
        updated_enrollments[enrollee_i].levels[level_i][position_i] = { color }


        rtiChange(null, enrollee_i, level_i, position_i, color, status)
        setEnrollments(updated_enrollments)
        resetRtiContextMenu()
    }

    function rtiChange(e, e_i, l_i, p_i, color=null, status=null) {
        let changed_changes = { ...rtiChanges }
        const position = `${e_i}-${l_i}-${p_i}`
        changed_changes[position] = {
            ...changed_changes[position],
            color: color || changed_changes[position]?.color || 'white',
            status: status || changed_changes[position]?.status || 0,
            credits: e?.target.value || changed_changes[position]?.credits,
            account_id: enrollments[e_i]?.id,
            course_id: courses[l_i]?.[p_i]?.Id,
            learner_id: enrollments[e_i]?.learner_id,
        }

        console.log(changed_changes)
        setRtiChanges(changed_changes)
    }

    async function saveChanges() {
        try {
            const resp = await axios.put(`${process.env.REACT_APP_API_URL}/rti/`, rtiChanges)
            console.log("resp:", resp)
        } catch (err) {
            console.error(err)
            toast.error("Saving changes failed, please try again later")
        }
    }

    async function asyncSetupRtiTracker() {
        try {
            console.log("LOADING RTI DATA...")
            // const rti_resp = await axios.get(`${process.env.REACT_APP_API_URL}/rti/`)
            // const rti_data = rti_resp?.data
            const rti_data = live_data
            console.log(rti_data)
            if (!rti_data) throw new Error('ERR_LOAD_RTI_FAILED')
            setCourses(rti_data.bundled_courses)
            setEnrollments(rti_data.bundled_enrollments)
            setIsLoading(false)
        } catch (err) {
            console.error(err)
            toast.error("Something went wrong, please try again later")
            setIsError(true)
        }
    }

    useEffect(() => {
        asyncSetupRtiTracker()
        document.addEventListener('click', rtiContextMenuLeftClick)
        return () => {
            document.removeEventListener('click', rtiContextMenuLeftClick)
        }
    }, [])

    return (
        <div className='mt-5 super-functionality'>
            <div>
                <div className='d-flex justify-content-start align-items-center'>
                    <h1 className='h1'>
                        RTI Tracker
                    </h1>
                    <i className="ml-3">(BETA - STILL IN DEVELOPMENT)</i>
                </div>
                {isError ?
                    <h4 className="h4 mt-3">Error, something went wrong...</h4>
                    : isLoading
                        ? <h4 className="h4 mt-3">Loading RTI Data...</h4>
                        :
                        <div className="course-table-wrapper">
                            <RTIContextMenu
                                onChange={rtiColorChange}
                                contextMenuRef={contextMenuRef}
                                isToggled={contextMenu.toggled}
                                positionX={contextMenu.position.x}
                                positionY={contextMenu.position.y} />
                            <table className="mt-3 course-table">
                                <thead>
                                    <tr key='rti-table-header' id='rti-table-header'>
                                        <th className="no-scroll">Learner</th>
                                        <th>Level</th>
                                        {Object.values(courses).map((level, level_i) => (
                                            <>
                                                {Object.values(level).map((course, course_i) => (
                                                    <th key={`rti-course-th-${level_i}-${course_i}`} id={`rti-course-th-${level_i}-${course_i}`} style={{ background: course_colors[level_i], border: `2px solid ${course_colors[level_i]}` }} className="course-header smaller-text">{course?.Name}</th>
                                                ))}
                                                <td className="level-divider"></td>
                                            </>
                                        ))}
                                        <th>Assigned</th>
                                        <th>Completed</th>
                                        <th>RTI Current</th>
                                        <th>RTI Overall</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {enrollments.map((enrollee, enrollee_i) => (
                                        <tr key={`rti-enrollee-tr-${enrollee_i}`} >
                                            <td className="no-scroll blackout smaller-text">{enrollee.name}</td>
                                            <td className="blackout">L{enrollee.current_level}</td>
                                            {Object.keys(courses).map((level_i) => (
                                                <>
                                                    {Object.keys(courses[level_i]).map((position_i) => (
                                                        <td
                                                            className='rti-input-cell'
                                                            onContextMenu={(e) => onEnrollmentContextMenu(e, { enrollee_i, level_i, position_i })}
                                                            key={`rti-enrollee-td-${level_i}-${position_i}`}
                                                            id={`rti-enrollee-td-${level_i}-${position_i}`}
                                                            style={{
                                                                background: enrollee.levels?.[level_i]?.[position_i]?.color || 'white'
                                                            }}>
                                                            <input onChange={(e) => rtiChange(e, enrollee_i, level_i, position_i)} className='rti-input' value={enrollee.levels?.[level_i]?.[position_i]?.credits} />
                                                        </td>
                                                    ))}
                                                    <td className="level-divider"></td>
                                                </>
                                            ))}
                                            <td className="blackout">{enrollee.assigned}</td>
                                            <td className="blackout">{enrollee.completed}</td>
                                            <td className="blackout">{enrollee.rti_current}</td>
                                            <td className="blackout">{enrollee.rti_overall}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button onClick={saveChanges}>Save</button>
                        </div>
                }
            </div>
        </div>
    )
}